export default [
  /** Management **/
  {
    path: '/management/invoices',
    name: 'management-invoices',
    component: () => import('@/views/management/billing/Invoices.vue'),
    meta: {
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Invoices',
          active: true,
        },
      ],
      subject: 'management-invoices',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/invoices/:id',
    name: 'management-invoice',
    props: true,
    component: () => import('@/views/management/billing/Invoice.vue'),
    meta: {
      pageTitle: 'Invoice',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Invoice',
          active: true,
        },
      ],
      subject: 'management-invoice',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/invoices/:id/print',
    name: 'management-invoice-print',
    props: true,
    component: () => import('@/views/management/billing/InvoicePrint.vue'),
    meta: {
      pageTitle: 'Invoice',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Invoice',
        },
        {
          text: 'Print',
          active: true,
        },
      ],
      subject: 'management-invoice',
      action: 'read',
      hasSettings: false
    },
  },
  /*{
    path: '/management/purchase-orders',
    name: 'management-purchase-orders',
    component: () => import('@/views/management/billing/purchase-orders/PurchaseOrders.vue'),
    meta: {
      pageTitle: 'Purchase Orders',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Purchase Orders',
          active: true,
        },
      ],
      subject: 'management-purchase-orders',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/purchase-orders/:id',
    name: 'management-purchase-order',
    props: true,
    component: () => import('@/views/management/billing/purchase-orders/PurchaseOrder.vue'),
    meta: {
      pageTitle: 'Purchase Order',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Purchase Orders',
          to: { name: 'management-purchase-orders' }
        },
        {
          text: 'Purchase Order',
          active: true,
        },
      ],
      subject: 'management-purchase-order',
      action: 'read',
      hasSettings: false
    },
  },*/
]
