export default [
    {
        path: '/printable/labels/students',
        name: 'printable-labels-students',
        component: () => import('@/views/printable/labels/Labels.vue'),
        meta: {
            pageTitle: 'Labels',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Student Labels',
                    active: true,
                },
            ],
            subject: 'printable-labels',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/printable/labels/schools',
        name: 'printable-labels-schools',
        component: () => import('@/views/printable/labels/SchoolLabels.vue'),
        meta: {
            pageTitle: 'Labels',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'School Labels',
                    active: true,
                },
            ],
            subject: 'printable-labels',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/printable/certificates',
        name: 'printable-certificates',
        component: () => import('@/views/printable/certificates/Certificates.vue'),
        meta: {
            pageTitle: 'Certificates',
            breadcrumb: [
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Certificates',
                    active: true,
                },
            ],
            subject: 'printable-certificates',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/printable/letters/teacher',
        name: 'printable-letters-teacher',
        component: () => import('@/views/printable/letters/TeacherLetters.vue'),
        meta: {
            pageTitle: 'Teacher Letter',
            breadcrumb: [
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Letters',
                    active: true,
                },
                {
                    text: 'Teacher',
                    active: true,
                },
            ],
            subject: 'printable-letters',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/printable/letters/student',
        name: 'printable-letters-student',
        component: () => import('@/views/printable/letters/StudentLetters.vue'),
        meta: {
            pageTitle: 'Student Letter',
            breadcrumb: [
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Letters',
                    active: true,
                },
                {
                    text: 'Student',
                    active: true,
                },
            ],
            subject: 'printable-letters',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/printable/letters/school',
        name: 'printable-letters-school',
        component: () => import('@/views/printable/letters/SchoolLetters.vue'),
        meta: {
            pageTitle: 'Teacher Letter',
            breadcrumb: [
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Letters',
                    active: true,
                },
                {
                    text: 'School',
                    active: true,
                },
            ],
            subject: 'printable-letters',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/printable/magazine-and-program',
        name: 'printable-magazine-and-program',
        component: () => import('@/views/printable/MagazineAndProgram.vue'),
        meta: {
            pageTitle: 'School Music News for Magazine and Program',
            breadcrumb: [
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Magazine and Program Data',
                    active: true,
                },
            ],
            subject: 'printable-magazine-and-program',
            action: 'read',
            hasSettings: false
        },
    },
]
