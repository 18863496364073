/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateApplication = /* GraphQL */ `
  subscription OnCreateApplication(
    $filter: ModelSubscriptionApplicationFilterInput
  ) {
    onCreateApplication(filter: $filter) {
      id
      festival {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      teacher {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      teacherID
      questions {
        lastYear {
          attended
          group
          part
          chair
          __typename
        }
        moreThanOne {
          response
          instruments
          __typename
        }
        organizations {
          school
          other
          __typename
        }
        experience {
          vocalJazzAccompaniment
          jazzEnsemble
          orchestral
          __typename
        }
        previousSoloRating {
          __typename
        }
        __typename
      }
      grading {
        tone
        rhythmicSense
        leadership
        maturity
        articulation
        intonation
        citizenship
        diction
        flexibility
        generalMusicianship
        attitude
        technique
        sightReading
        responsibility
        __typename
      }
      recommendation
      comments
      ranking {
        score
        local
        state
        __typename
      }
      selection {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      verified
      createdAt
      updatedAt
      studentApplicationsId
      applicationFestivalId
      applicationFormId
      applicationInstrumentId
      applicationSelectionId
      __typename
    }
  }
`;
export const onUpdateApplication = /* GraphQL */ `
  subscription OnUpdateApplication(
    $filter: ModelSubscriptionApplicationFilterInput
  ) {
    onUpdateApplication(filter: $filter) {
      id
      festival {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      teacher {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      teacherID
      questions {
        lastYear {
          attended
          group
          part
          chair
          __typename
        }
        moreThanOne {
          response
          instruments
          __typename
        }
        organizations {
          school
          other
          __typename
        }
        experience {
          vocalJazzAccompaniment
          jazzEnsemble
          orchestral
          __typename
        }
        previousSoloRating {
          __typename
        }
        __typename
      }
      grading {
        tone
        rhythmicSense
        leadership
        maturity
        articulation
        intonation
        citizenship
        diction
        flexibility
        generalMusicianship
        attitude
        technique
        sightReading
        responsibility
        __typename
      }
      recommendation
      comments
      ranking {
        score
        local
        state
        __typename
      }
      selection {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      verified
      createdAt
      updatedAt
      studentApplicationsId
      applicationFestivalId
      applicationFormId
      applicationInstrumentId
      applicationSelectionId
      __typename
    }
  }
`;
export const onDeleteApplication = /* GraphQL */ `
  subscription OnDeleteApplication(
    $filter: ModelSubscriptionApplicationFilterInput
  ) {
    onDeleteApplication(filter: $filter) {
      id
      festival {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      teacher {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      teacherID
      questions {
        lastYear {
          attended
          group
          part
          chair
          __typename
        }
        moreThanOne {
          response
          instruments
          __typename
        }
        organizations {
          school
          other
          __typename
        }
        experience {
          vocalJazzAccompaniment
          jazzEnsemble
          orchestral
          __typename
        }
        previousSoloRating {
          __typename
        }
        __typename
      }
      grading {
        tone
        rhythmicSense
        leadership
        maturity
        articulation
        intonation
        citizenship
        diction
        flexibility
        generalMusicianship
        attitude
        technique
        sightReading
        responsibility
        __typename
      }
      recommendation
      comments
      ranking {
        score
        local
        state
        __typename
      }
      selection {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      verified
      createdAt
      updatedAt
      studentApplicationsId
      applicationFestivalId
      applicationFormId
      applicationInstrumentId
      applicationSelectionId
      __typename
    }
  }
`;
export const onCreateBill = /* GraphQL */ `
  subscription OnCreateBill($filter: ModelSubscriptionBillFilterInput) {
    onCreateBill(filter: $filter) {
      id
      payments {
        type
        amount
        check
        received
        returned
        __typename
      }
      memo
      duplicates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBill = /* GraphQL */ `
  subscription OnUpdateBill($filter: ModelSubscriptionBillFilterInput) {
    onUpdateBill(filter: $filter) {
      id
      payments {
        type
        amount
        check
        received
        returned
        __typename
      }
      memo
      duplicates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBill = /* GraphQL */ `
  subscription OnDeleteBill($filter: ModelSubscriptionBillFilterInput) {
    onDeleteBill(filter: $filter) {
      id
      payments {
        type
        amount
        check
        received
        returned
        __typename
      }
      memo
      duplicates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDistrict = /* GraphQL */ `
  subscription OnCreateDistrict($filter: ModelSubscriptionDistrictFilterInput) {
    onCreateDistrict(filter: $filter) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      schools {
        items {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        nextToken
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtZoneId
      __typename
    }
  }
`;
export const onUpdateDistrict = /* GraphQL */ `
  subscription OnUpdateDistrict($filter: ModelSubscriptionDistrictFilterInput) {
    onUpdateDistrict(filter: $filter) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      schools {
        items {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        nextToken
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtZoneId
      __typename
    }
  }
`;
export const onDeleteDistrict = /* GraphQL */ `
  subscription OnDeleteDistrict($filter: ModelSubscriptionDistrictFilterInput) {
    onDeleteDistrict(filter: $filter) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      schools {
        items {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        nextToken
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtZoneId
      __typename
    }
  }
`;
export const onCreateDocumentCategory = /* GraphQL */ `
  subscription OnCreateDocumentCategory(
    $filter: ModelSubscriptionDocumentCategoryFilterInput
  ) {
    onCreateDocumentCategory(filter: $filter) {
      id
      slug
      title
      description
      icon
      documents {
        items {
          id
          slug
          categoryID
          title
          description
          content
          isPublished
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDocumentCategory = /* GraphQL */ `
  subscription OnUpdateDocumentCategory(
    $filter: ModelSubscriptionDocumentCategoryFilterInput
  ) {
    onUpdateDocumentCategory(filter: $filter) {
      id
      slug
      title
      description
      icon
      documents {
        items {
          id
          slug
          categoryID
          title
          description
          content
          isPublished
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDocumentCategory = /* GraphQL */ `
  subscription OnDeleteDocumentCategory(
    $filter: ModelSubscriptionDocumentCategoryFilterInput
  ) {
    onDeleteDocumentCategory(filter: $filter) {
      id
      slug
      title
      description
      icon
      documents {
        items {
          id
          slug
          categoryID
          title
          description
          content
          isPublished
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onCreateDocument(filter: $filter) {
      id
      slug
      category {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      categoryID
      title
      description
      content
      isPublished
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onUpdateDocument(filter: $filter) {
      id
      slug
      category {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      categoryID
      title
      description
      content
      isPublished
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onDeleteDocument(filter: $filter) {
      id
      slug
      category {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      categoryID
      title
      description
      content
      isPublished
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEnsemble = /* GraphQL */ `
  subscription OnCreateEnsemble($filter: ModelSubscriptionEnsembleFilterInput) {
    onCreateEnsemble(filter: $filter) {
      id
      slug
      name
      code
      parts
      instruments {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chair {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      chairId
      hasParts
      hasAlternates
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEnsemble = /* GraphQL */ `
  subscription OnUpdateEnsemble($filter: ModelSubscriptionEnsembleFilterInput) {
    onUpdateEnsemble(filter: $filter) {
      id
      slug
      name
      code
      parts
      instruments {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chair {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      chairId
      hasParts
      hasAlternates
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEnsemble = /* GraphQL */ `
  subscription OnDeleteEnsemble($filter: ModelSubscriptionEnsembleFilterInput) {
    onDeleteEnsemble(filter: $filter) {
      id
      slug
      name
      code
      parts
      instruments {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chair {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      chairId
      hasParts
      hasAlternates
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFestival = /* GraphQL */ `
  subscription OnCreateFestival($filter: ModelSubscriptionFestivalFilterInput) {
    onCreateFestival(filter: $filter) {
      id
      slug
      name
      site
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      festivalZoneId
      __typename
    }
  }
`;
export const onUpdateFestival = /* GraphQL */ `
  subscription OnUpdateFestival($filter: ModelSubscriptionFestivalFilterInput) {
    onUpdateFestival(filter: $filter) {
      id
      slug
      name
      site
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      festivalZoneId
      __typename
    }
  }
`;
export const onDeleteFestival = /* GraphQL */ `
  subscription OnDeleteFestival($filter: ModelSubscriptionFestivalFilterInput) {
    onDeleteFestival(filter: $filter) {
      id
      slug
      name
      site
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      festivalZoneId
      __typename
    }
  }
`;
export const onCreateForm = /* GraphQL */ `
  subscription OnCreateForm($filter: ModelSubscriptionFormFilterInput) {
    onCreateForm(filter: $filter) {
      id
      slug
      name
      type
      instruments {
        items {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateForm = /* GraphQL */ `
  subscription OnUpdateForm($filter: ModelSubscriptionFormFilterInput) {
    onUpdateForm(filter: $filter) {
      id
      slug
      name
      type
      instruments {
        items {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteForm = /* GraphQL */ `
  subscription OnDeleteForm($filter: ModelSubscriptionFormFilterInput) {
    onDeleteForm(filter: $filter) {
      id
      slug
      name
      type
      instruments {
        items {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInstrument = /* GraphQL */ `
  subscription OnCreateInstrument(
    $filter: ModelSubscriptionInstrumentFilterInput
  ) {
    onCreateInstrument(filter: $filter) {
      id
      slug
      name
      order
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ensembles {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      formInstrumentsId
      __typename
    }
  }
`;
export const onUpdateInstrument = /* GraphQL */ `
  subscription OnUpdateInstrument(
    $filter: ModelSubscriptionInstrumentFilterInput
  ) {
    onUpdateInstrument(filter: $filter) {
      id
      slug
      name
      order
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ensembles {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      formInstrumentsId
      __typename
    }
  }
`;
export const onDeleteInstrument = /* GraphQL */ `
  subscription OnDeleteInstrument(
    $filter: ModelSubscriptionInstrumentFilterInput
  ) {
    onDeleteInstrument(filter: $filter) {
      id
      slug
      name
      order
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ensembles {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      formInstrumentsId
      __typename
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
    onCreateInvoice(filter: $filter) {
      id
      number
      amount
      dueAt
      memo
      duplicates
      payments {
        items {
          type
          amount
          check
          received
          returned
          createdAt
          updatedAt
          id
          invoicePaymentsId
          __typename
        }
        nextToken
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      createdAt
      updatedAt
      invoiceStudentId
      __typename
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
    onUpdateInvoice(filter: $filter) {
      id
      number
      amount
      dueAt
      memo
      duplicates
      payments {
        items {
          type
          amount
          check
          received
          returned
          createdAt
          updatedAt
          id
          invoicePaymentsId
          __typename
        }
        nextToken
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      createdAt
      updatedAt
      invoiceStudentId
      __typename
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice($filter: ModelSubscriptionInvoiceFilterInput) {
    onDeleteInvoice(filter: $filter) {
      id
      number
      amount
      dueAt
      memo
      duplicates
      payments {
        items {
          type
          amount
          check
          received
          returned
          createdAt
          updatedAt
          id
          invoicePaymentsId
          __typename
        }
        nextToken
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      createdAt
      updatedAt
      invoiceStudentId
      __typename
    }
  }
`;
export const onCreatePurchaseOrder = /* GraphQL */ `
  subscription OnCreatePurchaseOrder(
    $filter: ModelSubscriptionPurchaseOrderFilterInput
  ) {
    onCreatePurchaseOrder(filter: $filter) {
      type
      amount
      check
      received
      returned
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onUpdatePurchaseOrder = /* GraphQL */ `
  subscription OnUpdatePurchaseOrder(
    $filter: ModelSubscriptionPurchaseOrderFilterInput
  ) {
    onUpdatePurchaseOrder(filter: $filter) {
      type
      amount
      check
      received
      returned
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onDeletePurchaseOrder = /* GraphQL */ `
  subscription OnDeletePurchaseOrder(
    $filter: ModelSubscriptionPurchaseOrderFilterInput
  ) {
    onDeletePurchaseOrder(filter: $filter) {
      type
      amount
      check
      received
      returned
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment($filter: ModelSubscriptionPaymentFilterInput) {
    onCreatePayment(filter: $filter) {
      type
      amount
      check
      received
      returned
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      createdAt
      updatedAt
      id
      invoicePaymentsId
      __typename
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment($filter: ModelSubscriptionPaymentFilterInput) {
    onUpdatePayment(filter: $filter) {
      type
      amount
      check
      received
      returned
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      createdAt
      updatedAt
      id
      invoicePaymentsId
      __typename
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment($filter: ModelSubscriptionPaymentFilterInput) {
    onDeletePayment(filter: $filter) {
      type
      amount
      check
      received
      returned
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      createdAt
      updatedAt
      id
      invoicePaymentsId
      __typename
    }
  }
`;
export const onCreateSchool = /* GraphQL */ `
  subscription OnCreateSchool($filter: ModelSubscriptionSchoolFilterInput) {
    onCreateSchool(filter: $filter) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      district {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtSchoolsId
      schoolZoneId
      __typename
    }
  }
`;
export const onUpdateSchool = /* GraphQL */ `
  subscription OnUpdateSchool($filter: ModelSubscriptionSchoolFilterInput) {
    onUpdateSchool(filter: $filter) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      district {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtSchoolsId
      schoolZoneId
      __typename
    }
  }
`;
export const onDeleteSchool = /* GraphQL */ `
  subscription OnDeleteSchool($filter: ModelSubscriptionSchoolFilterInput) {
    onDeleteSchool(filter: $filter) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      district {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtSchoolsId
      schoolZoneId
      __typename
    }
  }
`;
export const onCreateSelection = /* GraphQL */ `
  subscription OnCreateSelection(
    $filter: ModelSubscriptionSelectionFilterInput
  ) {
    onCreateSelection(filter: $filter) {
      id
      part
      ranking {
        state
        __typename
      }
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      accepted
      application {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      state {
        order
        __typename
      }
      createdAt
      updatedAt
      selectionEnsembleId
      selectionApplicationId
      __typename
    }
  }
`;
export const onUpdateSelection = /* GraphQL */ `
  subscription OnUpdateSelection(
    $filter: ModelSubscriptionSelectionFilterInput
  ) {
    onUpdateSelection(filter: $filter) {
      id
      part
      ranking {
        state
        __typename
      }
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      accepted
      application {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      state {
        order
        __typename
      }
      createdAt
      updatedAt
      selectionEnsembleId
      selectionApplicationId
      __typename
    }
  }
`;
export const onDeleteSelection = /* GraphQL */ `
  subscription OnDeleteSelection(
    $filter: ModelSubscriptionSelectionFilterInput
  ) {
    onDeleteSelection(filter: $filter) {
      id
      part
      ranking {
        state
        __typename
      }
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      accepted
      application {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      state {
        order
        __typename
      }
      createdAt
      updatedAt
      selectionEnsembleId
      selectionApplicationId
      __typename
    }
  }
`;
export const onCreateSetting = /* GraphQL */ `
  subscription OnCreateSetting($filter: ModelSubscriptionSettingFilterInput) {
    onCreateSetting(filter: $filter) {
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSetting = /* GraphQL */ `
  subscription OnUpdateSetting($filter: ModelSubscriptionSettingFilterInput) {
    onUpdateSetting(filter: $filter) {
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSetting = /* GraphQL */ `
  subscription OnDeleteSetting($filter: ModelSubscriptionSettingFilterInput) {
    onDeleteSetting(filter: $filter) {
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStudent = /* GraphQL */ `
  subscription OnCreateStudent($filter: ModelSubscriptionStudentFilterInput) {
    onCreateStudent(filter: $filter) {
      id
      name {
        first
        last
        preferred
        __typename
      }
      dob
      sex
      gender
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      grade
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      schoolID
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      applications {
        items {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        nextToken
        __typename
      }
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      eventId
      event {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      busStudentsId
      chaperoneStudentsId
      hotelStudentsId
      studentInvoiceId
      __typename
    }
  }
`;
export const onUpdateStudent = /* GraphQL */ `
  subscription OnUpdateStudent($filter: ModelSubscriptionStudentFilterInput) {
    onUpdateStudent(filter: $filter) {
      id
      name {
        first
        last
        preferred
        __typename
      }
      dob
      sex
      gender
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      grade
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      schoolID
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      applications {
        items {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        nextToken
        __typename
      }
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      eventId
      event {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      busStudentsId
      chaperoneStudentsId
      hotelStudentsId
      studentInvoiceId
      __typename
    }
  }
`;
export const onDeleteStudent = /* GraphQL */ `
  subscription OnDeleteStudent($filter: ModelSubscriptionStudentFilterInput) {
    onDeleteStudent(filter: $filter) {
      id
      name {
        first
        last
        preferred
        __typename
      }
      dob
      sex
      gender
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      grade
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      schoolID
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      applications {
        items {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        nextToken
        __typename
      }
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      eventId
      event {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      busStudentsId
      chaperoneStudentsId
      hotelStudentsId
      studentInvoiceId
      __typename
    }
  }
`;
export const onCreateTicket = /* GraphQL */ `
  subscription OnCreateTicket($filter: ModelSubscriptionTicketFilterInput) {
    onCreateTicket(filter: $filter) {
      id
      title
      text
      status
      labels
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      userID
      replies {
        items {
          id
          text
          isAutomatedReply
          createdAt
          updatedAt
          ticketRepliesId
          ticketReplyUserId
          __typename
        }
        nextToken
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTicket = /* GraphQL */ `
  subscription OnUpdateTicket($filter: ModelSubscriptionTicketFilterInput) {
    onUpdateTicket(filter: $filter) {
      id
      title
      text
      status
      labels
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      userID
      replies {
        items {
          id
          text
          isAutomatedReply
          createdAt
          updatedAt
          ticketRepliesId
          ticketReplyUserId
          __typename
        }
        nextToken
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTicket = /* GraphQL */ `
  subscription OnDeleteTicket($filter: ModelSubscriptionTicketFilterInput) {
    onDeleteTicket(filter: $filter) {
      id
      title
      text
      status
      labels
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      userID
      replies {
        items {
          id
          text
          isAutomatedReply
          createdAt
          updatedAt
          ticketRepliesId
          ticketReplyUserId
          __typename
        }
        nextToken
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTicketReply = /* GraphQL */ `
  subscription OnCreateTicketReply(
    $filter: ModelSubscriptionTicketReplyFilterInput
  ) {
    onCreateTicketReply(filter: $filter) {
      id
      text
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      ticket {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      isAutomatedReply
      createdAt
      updatedAt
      ticketRepliesId
      ticketReplyUserId
      __typename
    }
  }
`;
export const onUpdateTicketReply = /* GraphQL */ `
  subscription OnUpdateTicketReply(
    $filter: ModelSubscriptionTicketReplyFilterInput
  ) {
    onUpdateTicketReply(filter: $filter) {
      id
      text
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      ticket {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      isAutomatedReply
      createdAt
      updatedAt
      ticketRepliesId
      ticketReplyUserId
      __typename
    }
  }
`;
export const onDeleteTicketReply = /* GraphQL */ `
  subscription OnDeleteTicketReply(
    $filter: ModelSubscriptionTicketReplyFilterInput
  ) {
    onDeleteTicketReply(filter: $filter) {
      id
      text
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      ticket {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      isAutomatedReply
      createdAt
      updatedAt
      ticketRepliesId
      ticketReplyUserId
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      username
      name {
        first
        last
        preferred
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      schools {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ensembleIds
      instrumentIds
      zoneIds
      avatar {
        size
        name
        src
        __typename
      }
      createdAt
      updatedAt
      districtUsersId
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      username
      name {
        first
        last
        preferred
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      schools {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ensembleIds
      instrumentIds
      zoneIds
      avatar {
        size
        name
        src
        __typename
      }
      createdAt
      updatedAt
      districtUsersId
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      username
      name {
        first
        last
        preferred
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      schools {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ensembleIds
      instrumentIds
      zoneIds
      avatar {
        size
        name
        src
        __typename
      }
      createdAt
      updatedAt
      districtUsersId
      __typename
    }
  }
`;
export const onCreateZone = /* GraphQL */ `
  subscription OnCreateZone($filter: ModelSubscriptionZoneFilterInput) {
    onCreateZone(filter: $filter) {
      id
      name
      counties
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateZone = /* GraphQL */ `
  subscription OnUpdateZone($filter: ModelSubscriptionZoneFilterInput) {
    onUpdateZone(filter: $filter) {
      id
      name
      counties
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteZone = /* GraphQL */ `
  subscription OnDeleteZone($filter: ModelSubscriptionZoneFilterInput) {
    onDeleteZone(filter: $filter) {
      id
      name
      counties
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStudentEvent = /* GraphQL */ `
  subscription OnCreateStudentEvent(
    $filter: ModelSubscriptionStudentEventFilterInput
  ) {
    onCreateStudentEvent(filter: $filter) {
      id
      studentId
      busId
      chaperoneId
      hotelId
      room
      checkin {
        thursday
        friday
        saturday
        __typename
      }
      signature
      medicalReceived
      notes {
        id
        type
        text
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chaperone {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStudentEvent = /* GraphQL */ `
  subscription OnUpdateStudentEvent(
    $filter: ModelSubscriptionStudentEventFilterInput
  ) {
    onUpdateStudentEvent(filter: $filter) {
      id
      studentId
      busId
      chaperoneId
      hotelId
      room
      checkin {
        thursday
        friday
        saturday
        __typename
      }
      signature
      medicalReceived
      notes {
        id
        type
        text
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chaperone {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStudentEvent = /* GraphQL */ `
  subscription OnDeleteStudentEvent(
    $filter: ModelSubscriptionStudentEventFilterInput
  ) {
    onDeleteStudentEvent(filter: $filter) {
      id
      studentId
      busId
      chaperoneId
      hotelId
      room
      checkin {
        thursday
        friday
        saturday
        __typename
      }
      signature
      medicalReceived
      notes {
        id
        type
        text
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chaperone {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBus = /* GraphQL */ `
  subscription OnCreateBus($filter: ModelSubscriptionBusFilterInput) {
    onCreateBus(filter: $filter) {
      id
      name
      capacity
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBus = /* GraphQL */ `
  subscription OnUpdateBus($filter: ModelSubscriptionBusFilterInput) {
    onUpdateBus(filter: $filter) {
      id
      name
      capacity
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBus = /* GraphQL */ `
  subscription OnDeleteBus($filter: ModelSubscriptionBusFilterInput) {
    onDeleteBus(filter: $filter) {
      id
      name
      capacity
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChaperone = /* GraphQL */ `
  subscription OnCreateChaperone(
    $filter: ModelSubscriptionChaperoneFilterInput
  ) {
    onCreateChaperone(filter: $filter) {
      id
      name {
        prefix
        first
        last
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      role
      room
      hotelId
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChaperone = /* GraphQL */ `
  subscription OnUpdateChaperone(
    $filter: ModelSubscriptionChaperoneFilterInput
  ) {
    onUpdateChaperone(filter: $filter) {
      id
      name {
        prefix
        first
        last
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      role
      room
      hotelId
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChaperone = /* GraphQL */ `
  subscription OnDeleteChaperone(
    $filter: ModelSubscriptionChaperoneFilterInput
  ) {
    onDeleteChaperone(filter: $filter) {
      id
      name {
        prefix
        first
        last
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      role
      room
      hotelId
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateHotel = /* GraphQL */ `
  subscription OnCreateHotel($filter: ModelSubscriptionHotelFilterInput) {
    onCreateHotel(filter: $filter) {
      id
      name
      phone {
        type
        number
        ext
        __typename
      }
      medicalSuite
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateHotel = /* GraphQL */ `
  subscription OnUpdateHotel($filter: ModelSubscriptionHotelFilterInput) {
    onUpdateHotel(filter: $filter) {
      id
      name
      phone {
        type
        number
        ext
        __typename
      }
      medicalSuite
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteHotel = /* GraphQL */ `
  subscription OnDeleteHotel($filter: ModelSubscriptionHotelFilterInput) {
    onDeleteHotel(filter: $filter) {
      id
      name
      phone {
        type
        number
        ext
        __typename
      }
      medicalSuite
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDev = /* GraphQL */ `
  subscription OnCreateDev($filter: ModelSubscriptionDevFilterInput) {
    onCreateDev(filter: $filter) {
      id
      name
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDev = /* GraphQL */ `
  subscription OnUpdateDev($filter: ModelSubscriptionDevFilterInput) {
    onUpdateDev(filter: $filter) {
      id
      name
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDev = /* GraphQL */ `
  subscription OnDeleteDev($filter: ModelSubscriptionDevFilterInput) {
    onDeleteDev(filter: $filter) {
      id
      name
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEnsembleInstruments = /* GraphQL */ `
  subscription OnCreateEnsembleInstruments(
    $filter: ModelSubscriptionEnsembleInstrumentsFilterInput
  ) {
    onCreateEnsembleInstruments(filter: $filter) {
      id
      ensembleID
      instrumentID
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEnsembleInstruments = /* GraphQL */ `
  subscription OnUpdateEnsembleInstruments(
    $filter: ModelSubscriptionEnsembleInstrumentsFilterInput
  ) {
    onUpdateEnsembleInstruments(filter: $filter) {
      id
      ensembleID
      instrumentID
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEnsembleInstruments = /* GraphQL */ `
  subscription OnDeleteEnsembleInstruments(
    $filter: ModelSubscriptionEnsembleInstrumentsFilterInput
  ) {
    onDeleteEnsembleInstruments(filter: $filter) {
      id
      ensembleID
      instrumentID
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserSchools = /* GraphQL */ `
  subscription OnCreateUserSchools(
    $filter: ModelSubscriptionUserSchoolsFilterInput
  ) {
    onCreateUserSchools(filter: $filter) {
      id
      schoolID
      userID
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserSchools = /* GraphQL */ `
  subscription OnUpdateUserSchools(
    $filter: ModelSubscriptionUserSchoolsFilterInput
  ) {
    onUpdateUserSchools(filter: $filter) {
      id
      schoolID
      userID
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserSchools = /* GraphQL */ `
  subscription OnDeleteUserSchools(
    $filter: ModelSubscriptionUserSchoolsFilterInput
  ) {
    onDeleteUserSchools(filter: $filter) {
      id
      schoolID
      userID
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
