import ability from './ability'

export function canNavigate(to) {
    return to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.subject))
}

export function canManage(to) {
    return to.matched.some(route => ability.can('manage', to.name))
}

export const _ = undefined
