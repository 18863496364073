/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      festival {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      teacher {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      teacherID
      questions {
        lastYear {
          attended
          group
          part
          chair
          __typename
        }
        moreThanOne {
          response
          instruments
          __typename
        }
        organizations {
          school
          other
          __typename
        }
        experience {
          vocalJazzAccompaniment
          jazzEnsemble
          orchestral
          __typename
        }
        previousSoloRating {
          __typename
        }
        __typename
      }
      grading {
        tone
        rhythmicSense
        leadership
        maturity
        articulation
        intonation
        citizenship
        diction
        flexibility
        generalMusicianship
        attitude
        technique
        sightReading
        responsibility
        __typename
      }
      recommendation
      comments
      ranking {
        score
        local
        state
        __typename
      }
      selection {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      verified
      createdAt
      updatedAt
      studentApplicationsId
      applicationFestivalId
      applicationFormId
      applicationInstrumentId
      applicationSelectionId
      __typename
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $id: ID
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listApplicationsByTeacher = /* GraphQL */ `
  query ListApplicationsByTeacher(
    $teacherID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplicationsByTeacher(
      teacherID: $teacherID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBill = /* GraphQL */ `
  query GetBill($id: ID!) {
    getBill(id: $id) {
      id
      payments {
        type
        amount
        check
        received
        returned
        __typename
      }
      memo
      duplicates
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBills = /* GraphQL */ `
  query ListBills(
    $id: ID
    $filter: ModelBillFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBills(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        payments {
          type
          amount
          check
          received
          returned
          __typename
        }
        memo
        duplicates
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDistrict = /* GraphQL */ `
  query GetDistrict($id: ID!) {
    getDistrict(id: $id) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      schools {
        items {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        nextToken
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtZoneId
      __typename
    }
  }
`;
export const listDistricts = /* GraphQL */ `
  query ListDistricts(
    $id: ID
    $filter: ModelDistrictFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDistricts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDistrictBySlug = /* GraphQL */ `
  query GetDistrictBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDistrictFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getDistrictBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentCategory = /* GraphQL */ `
  query GetDocumentCategory($id: ID!) {
    getDocumentCategory(id: $id) {
      id
      slug
      title
      description
      icon
      documents {
        items {
          id
          slug
          categoryID
          title
          description
          content
          isPublished
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocumentCategories = /* GraphQL */ `
  query ListDocumentCategories(
    $id: ID
    $filter: ModelDocumentCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocumentCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listDocumentCategoryBySlug = /* GraphQL */ `
  query ListDocumentCategoryBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentCategoryBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      slug
      category {
        id
        slug
        title
        description
        icon
        documents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      categoryID
      title
      description
      content
      isPublished
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $id: ID
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        category {
          id
          slug
          title
          description
          icon
          createdAt
          updatedAt
          __typename
        }
        categoryID
        title
        description
        content
        isPublished
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listDocumentsBySlug = /* GraphQL */ `
  query ListDocumentsBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentsBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        category {
          id
          slug
          title
          description
          icon
          createdAt
          updatedAt
          __typename
        }
        categoryID
        title
        description
        content
        isPublished
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listDocumentsByCategory = /* GraphQL */ `
  query ListDocumentsByCategory(
    $categoryID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentsByCategory(
      categoryID: $categoryID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        category {
          id
          slug
          title
          description
          icon
          createdAt
          updatedAt
          __typename
        }
        categoryID
        title
        description
        content
        isPublished
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnsemble = /* GraphQL */ `
  query GetEnsemble($id: ID!) {
    getEnsemble(id: $id) {
      id
      slug
      name
      code
      parts
      instruments {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      chair {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      chairId
      hasParts
      hasAlternates
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnsembles = /* GraphQL */ `
  query ListEnsembles(
    $id: ID
    $filter: ModelEnsembleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnsembles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnsembleBySlug = /* GraphQL */ `
  query GetEnsembleBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEnsembleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEnsembleBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listEnsemblesByChairId = /* GraphQL */ `
  query ListEnsemblesByChairId(
    $chairId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEnsembleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnsemblesByChairId(
      chairId: $chairId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFestival = /* GraphQL */ `
  query GetFestival($id: ID!) {
    getFestival(id: $id) {
      id
      slug
      name
      site
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      festivalZoneId
      __typename
    }
  }
`;
export const listFestivals = /* GraphQL */ `
  query ListFestivals(
    $id: ID
    $filter: ModelFestivalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFestivals(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFestivalBySlug = /* GraphQL */ `
  query GetFestivalBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFestivalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFestivalBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        site
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        festivalZoneId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      slug
      name
      type
      instruments {
        items {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $id: ID
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listForms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFormBySlug = /* GraphQL */ `
  query GetFormBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFormBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInstrument = /* GraphQL */ `
  query GetInstrument($id: ID!) {
    getInstrument(id: $id) {
      id
      slug
      name
      order
      form {
        id
        slug
        name
        type
        instruments {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ensembles {
        items {
          id
          ensembleID
          instrumentID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      formInstrumentsId
      __typename
    }
  }
`;
export const listInstruments = /* GraphQL */ `
  query ListInstruments(
    $id: ID
    $filter: ModelInstrumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstruments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInstrumentBySlug = /* GraphQL */ `
  query GetInstrumentBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInstrumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInstrumentBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      number
      amount
      dueAt
      memo
      duplicates
      payments {
        items {
          type
          amount
          check
          received
          returned
          createdAt
          updatedAt
          id
          invoicePaymentsId
          __typename
        }
        nextToken
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      createdAt
      updatedAt
      invoiceStudentId
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $id: ID
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInvoices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchaseOrder = /* GraphQL */ `
  query GetPurchaseOrder($id: ID!) {
    getPurchaseOrder(id: $id) {
      type
      amount
      check
      received
      returned
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const listPurchaseOrders = /* GraphQL */ `
  query ListPurchaseOrders(
    $filter: ModelPurchaseOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchaseOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        amount
        check
        received
        returned
        createdAt
        updatedAt
        id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      type
      amount
      check
      received
      returned
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      createdAt
      updatedAt
      id
      invoicePaymentsId
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        amount
        check
        received
        returned
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        createdAt
        updatedAt
        id
        invoicePaymentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      beds
      slug
      name {
        legal
        popular
        __typename
      }
      avatar {
        size
        name
        src
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      zone {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      district {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        schools {
          nextToken
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtZoneId
        __typename
      }
      staff {
        type
        name {
          first
          last
          preferred
          __typename
        }
        title
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        __typename
      }
      users {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      districtSchoolsId
      schoolZoneId
      __typename
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $id: ID
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchools(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSchoolBySlug = /* GraphQL */ `
  query GetSchoolBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSelection = /* GraphQL */ `
  query GetSelection($id: ID!) {
    getSelection(id: $id) {
      id
      part
      ranking {
        state
        __typename
      }
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      accepted
      application {
        id
        festival {
          id
          slug
          name
          site
          createdAt
          updatedAt
          festivalZoneId
          __typename
        }
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        teacher {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        teacherID
        questions {
          __typename
        }
        grading {
          tone
          rhythmicSense
          leadership
          maturity
          articulation
          intonation
          citizenship
          diction
          flexibility
          generalMusicianship
          attitude
          technique
          sightReading
          responsibility
          __typename
        }
        recommendation
        comments
        ranking {
          score
          local
          state
          __typename
        }
        selection {
          id
          part
          accepted
          createdAt
          updatedAt
          selectionEnsembleId
          selectionApplicationId
          __typename
        }
        verified
        createdAt
        updatedAt
        studentApplicationsId
        applicationFestivalId
        applicationFormId
        applicationInstrumentId
        applicationSelectionId
        __typename
      }
      state {
        order
        __typename
      }
      createdAt
      updatedAt
      selectionEnsembleId
      selectionApplicationId
      __typename
    }
  }
`;
export const listSelections = /* GraphQL */ `
  query ListSelections(
    $id: ID
    $filter: ModelSelectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSelections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        part
        ranking {
          state
          __typename
        }
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        accepted
        application {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        state {
          order
          __typename
        }
        createdAt
        updatedAt
        selectionEnsembleId
        selectionApplicationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($key: String!) {
    getSetting(key: $key) {
      key
      value
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $key: String
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      key: $key
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        key
        value
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      name {
        first
        last
        preferred
        __typename
      }
      dob
      sex
      gender
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      grade
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      schoolID
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      applications {
        items {
          id
          teacherID
          recommendation
          comments
          verified
          createdAt
          updatedAt
          studentApplicationsId
          applicationFestivalId
          applicationFormId
          applicationInstrumentId
          applicationSelectionId
          __typename
        }
        nextToken
        __typename
      }
      invoice {
        id
        number
        amount
        dueAt
        memo
        duplicates
        payments {
          nextToken
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        createdAt
        updatedAt
        invoiceStudentId
        __typename
      }
      eventId
      event {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      busStudentsId
      chaperoneStudentsId
      hotelStudentsId
      studentInvoiceId
      __typename
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $id: ID
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStudentsBySchool = /* GraphQL */ `
  query ListStudentsBySchool(
    $schoolID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsBySchool(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($id: ID!) {
    getTicket(id: $id) {
      id
      title
      text
      status
      labels
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      userID
      replies {
        items {
          id
          text
          isAutomatedReply
          createdAt
          updatedAt
          ticketRepliesId
          ticketReplyUserId
          __typename
        }
        nextToken
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets(
    $id: ID
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTickets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTicketsByStatus = /* GraphQL */ `
  query ListTicketsByStatus(
    $status: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTicketsByUser = /* GraphQL */ `
  query ListTicketsByUser(
    $userID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTicketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTicketsByUser(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTicketReply = /* GraphQL */ `
  query GetTicketReply($id: ID!) {
    getTicketReply(id: $id) {
      id
      text
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      ticket {
        id
        title
        text
        status
        labels
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        userID
        replies {
          nextToken
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      state {
        locked
        hidden
        __typename
      }
      isAutomatedReply
      createdAt
      updatedAt
      ticketRepliesId
      ticketReplyUserId
      __typename
    }
  }
`;
export const listTicketReplies = /* GraphQL */ `
  query ListTicketReplies(
    $id: ID
    $filter: ModelTicketReplyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTicketReplies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        text
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        ticket {
          id
          title
          text
          status
          labels
          userID
          createdAt
          updatedAt
          __typename
        }
        state {
          locked
          hidden
          __typename
        }
        isAutomatedReply
        createdAt
        updatedAt
        ticketRepliesId
        ticketReplyUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      name {
        first
        last
        preferred
        __typename
      }
      address {
        line1
        line2
        city
        county
        state
        zip
        __typename
      }
      schools {
        items {
          id
          schoolID
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ensembleIds
      instrumentIds
      zoneIds
      avatar {
        size
        name
        src
        __typename
      }
      createdAt
      updatedAt
      districtUsersId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByUsername = /* GraphQL */ `
  query GetUserByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      id
      name
      counties
      state {
        enabled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $id: ID
    $filter: ModelZoneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZones(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        counties
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudentEvent = /* GraphQL */ `
  query GetStudentEvent($id: ID!) {
    getStudentEvent(id: $id) {
      id
      studentId
      busId
      chaperoneId
      hotelId
      room
      checkin {
        thursday
        friday
        saturday
        __typename
      }
      signature
      medicalReceived
      notes {
        id
        type
        text
        __typename
      }
      student {
        id
        name {
          first
          last
          preferred
          __typename
        }
        dob
        sex
        gender
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        grade
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        schoolID
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        applications {
          nextToken
          __typename
        }
        invoice {
          id
          number
          amount
          dueAt
          memo
          duplicates
          createdAt
          updatedAt
          invoiceStudentId
          __typename
        }
        eventId
        event {
          id
          studentId
          busId
          chaperoneId
          hotelId
          room
          signature
          medicalReceived
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        busStudentsId
        chaperoneStudentsId
        hotelStudentsId
        studentInvoiceId
        __typename
      }
      bus {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      chaperone {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStudentEvents = /* GraphQL */ `
  query ListStudentEvents(
    $id: ID
    $filter: ModelStudentEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudentEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStudentEventsByStudent = /* GraphQL */ `
  query ListStudentEventsByStudent(
    $studentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentEventsByStudent(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStudentEventsByBus = /* GraphQL */ `
  query ListStudentEventsByBus(
    $busId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentEventsByBus(
      busId: $busId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStudentEventsByChaperone = /* GraphQL */ `
  query ListStudentEventsByChaperone(
    $chaperoneId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentEventsByChaperone(
      chaperoneId: $chaperoneId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listStudentEventsByHotel = /* GraphQL */ `
  query ListStudentEventsByHotel(
    $hotelId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentEventsByHotel(
      hotelId: $hotelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentId
        busId
        chaperoneId
        hotelId
        room
        checkin {
          thursday
          friday
          saturday
          __typename
        }
        signature
        medicalReceived
        notes {
          id
          type
          text
          __typename
        }
        student {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        bus {
          id
          name
          capacity
          createdAt
          updatedAt
          __typename
        }
        chaperone {
          id
          role
          room
          hotelId
          createdAt
          updatedAt
          __typename
        }
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBus = /* GraphQL */ `
  query GetBus($id: ID!) {
    getBus(id: $id) {
      id
      name
      capacity
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBuses = /* GraphQL */ `
  query ListBuses(
    $id: ID
    $filter: ModelBusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBuses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        capacity
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChaperone = /* GraphQL */ `
  query GetChaperone($id: ID!) {
    getChaperone(id: $id) {
      id
      name {
        prefix
        first
        last
        __typename
      }
      phone {
        type
        number
        ext
        __typename
      }
      email {
        type
        address
        __typename
      }
      role
      room
      hotelId
      hotel {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChaperones = /* GraphQL */ `
  query ListChaperones(
    $id: ID
    $filter: ModelChaperoneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChaperones(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listChaperonesByHotel = /* GraphQL */ `
  query ListChaperonesByHotel(
    $hotelId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChaperoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChaperonesByHotel(
      hotelId: $hotelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name {
          prefix
          first
          last
          __typename
        }
        phone {
          type
          number
          ext
          __typename
        }
        email {
          type
          address
          __typename
        }
        role
        room
        hotelId
        hotel {
          id
          name
          medicalSuite
          createdAt
          updatedAt
          __typename
        }
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHotel = /* GraphQL */ `
  query GetHotel($id: ID!) {
    getHotel(id: $id) {
      id
      name
      phone {
        type
        number
        ext
        __typename
      }
      medicalSuite
      students {
        items {
          id
          dob
          sex
          gender
          grade
          schoolID
          eventId
          createdAt
          updatedAt
          busStudentsId
          chaperoneStudentsId
          hotelStudentsId
          studentInvoiceId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHotels = /* GraphQL */ `
  query ListHotels(
    $id: ID
    $filter: ModelHotelFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHotels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        phone {
          type
          number
          ext
          __typename
        }
        medicalSuite
        students {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDev = /* GraphQL */ `
  query GetDev($id: ID!) {
    getDev(id: $id) {
      id
      name
      year
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDevs = /* GraphQL */ `
  query ListDevs(
    $id: ID
    $filter: ModelDevFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDevs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        year
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listDevsByYear = /* GraphQL */ `
  query ListDevsByYear(
    $year: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelDevFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevsByYear(
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        year
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnsembleInstruments = /* GraphQL */ `
  query GetEnsembleInstruments($id: ID!) {
    getEnsembleInstruments(id: $id) {
      id
      ensembleID
      instrumentID
      ensemble {
        id
        slug
        name
        code
        parts
        instruments {
          nextToken
          __typename
        }
        chair {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        chairId
        hasParts
        hasAlternates
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      instrument {
        id
        slug
        name
        order
        form {
          id
          slug
          name
          type
          createdAt
          updatedAt
          __typename
        }
        ensembles {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        formInstrumentsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnsembleInstruments = /* GraphQL */ `
  query ListEnsembleInstruments(
    $filter: ModelEnsembleInstrumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnsembleInstruments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ensembleID
        instrumentID
        ensemble {
          id
          slug
          name
          code
          parts
          chairId
          hasParts
          hasAlternates
          createdAt
          updatedAt
          __typename
        }
        instrument {
          id
          slug
          name
          order
          createdAt
          updatedAt
          formInstrumentsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserSchools = /* GraphQL */ `
  query GetUserSchools($id: ID!) {
    getUserSchools(id: $id) {
      id
      schoolID
      userID
      school {
        id
        beds
        slug
        name {
          legal
          popular
          __typename
        }
        avatar {
          size
          name
          src
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        zone {
          id
          name
          counties
          createdAt
          updatedAt
          __typename
        }
        district {
          id
          beds
          slug
          createdAt
          updatedAt
          districtZoneId
          __typename
        }
        staff {
          type
          title
          __typename
        }
        users {
          nextToken
          __typename
        }
        state {
          enabled
          __typename
        }
        createdAt
        updatedAt
        districtSchoolsId
        schoolZoneId
        __typename
      }
      user {
        id
        username
        name {
          first
          last
          preferred
          __typename
        }
        address {
          line1
          line2
          city
          county
          state
          zip
          __typename
        }
        schools {
          nextToken
          __typename
        }
        ensembleIds
        instrumentIds
        zoneIds
        avatar {
          size
          name
          src
          __typename
        }
        createdAt
        updatedAt
        districtUsersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserSchools = /* GraphQL */ `
  query ListUserSchools(
    $filter: ModelUserSchoolsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolID
        userID
        school {
          id
          beds
          slug
          createdAt
          updatedAt
          districtSchoolsId
          schoolZoneId
          __typename
        }
        user {
          id
          username
          ensembleIds
          instrumentIds
          zoneIds
          createdAt
          updatedAt
          districtUsersId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
