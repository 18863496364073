export default [
    /** All State **/
    {
        path: '/all-state/applications',
        name: 'all-state-applications',
        component: () => import('@/views/all-state/application/Applications.vue'),
        meta: {
            pageTitle: 'Applications',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Applications',
                    active: true,
                },
            ],
            subject: 'all-state-applications',
            action: 'read',
            hasSettings: true
        },
    },
    {
        /* Needs to be above route path: /all-state/applications/:id */
        path: '/all-state/applications/create',
        name: 'all-state-application-create',
        component: () => import('@/views/all-state/application/Application.vue'),
        meta: {
            pageTitle: 'New Application',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Applications',
                    to: { name: 'all-state-applications' }
                },
                {
                    text: 'New Application',
                    active: true,
                },
            ],
            subject: 'all-state-application-create',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/all-state/applications/:id',
        name: 'all-state-application',
        props: true,
        component: () => import('@/views/all-state/application/Application.vue'),
        meta: {
            pageTitle: 'Application',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Applications',
                    to: { name: 'all-state-applications' }
                },
                {
                    text: 'Application',
                    active: true,
                },
            ],
            subject: 'all-state-application',
            action: 'read',
            hasSettings: false
        },
    },

    {
        path: '/all-state/students',
        name: 'all-state-students',
        component: () => import('@/views/all-state/students/Students.vue'),
        meta: {
            pageTitle: 'Students',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Students',
                    active: true,
                },
            ],
            subject: 'all-state-students',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/all-state/students/:id',
        name: 'all-state-student',
        props: true,
        component: () => import('@/views/all-state/students/Student.vue'),
        meta: {
            pageTitle: 'Student',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Students',
                    to: { name: 'all-state-students' }
                },
                {
                    text: 'Student',
                    active: true,
                },
            ],
            subject: 'all-state-student',
            action: 'read',
            hasSettings: false
        },
    },

    /*{
        path: '/all-state/billing/purchase-orders',
        name: 'all-state-purchase-orders',
        component: () => import('@/views/all-state/billing/PurchaseOrders.vue'),
        meta: {
            pageTitle: 'Purchase Orders',
            breadcrumb: [
                {
                    text: 'All-State',
                },

                {
                    text: 'Purchase Orders',
                    active: true,
                },
            ],
            subject: 'all-state-purchase-orders',
            action: 'read',
        },
    },
    {
        path: '/all-state/billing/invoices',
        name: 'all-state-bills',
        component: () => import('@/views/all-state/billing/Bills.vue'),
        meta: {
            pageTitle: 'Invoices',
            breadcrumb: [
                {
                    text: 'All-State',
                },

                {
                    text: 'Invoices',
                    active: true,
                },
            ],
            subject: 'all-state-billing',
            action: 'read',
        },
    },
    {
        path: '/all-state/billing/invoice/:id',
        name: 'all-state-billing',
        props: true,
        component: () => import('@/views/all-state/billing/Bill.vue'),
        meta: {
            pageTitle: 'Billing',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Invoices',
                    to: { name: 'all-state-bills' }
                },
                {
                    text: 'Invoice',
                    active: true,
                },
            ],
            subject: 'all-state-billing',
            action: 'read',
        },
    },*/

    /** SCORES **/
    {
        path: '/all-state/scores',
        name: 'all-state-scores',
        component: () => import('@/views/all-state/scores/ScoresTable.vue'),
        meta: {
            pageTitle: 'Scores',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Scores & Ranking',
                    active: true,
                },
            ],
            subject: 'all-state-scores',
            action: 'read',
            hasSettings: true
        },
    },

    /** SELECTIONS **/
    {
        path: '/all-state/selections',
        name: 'all-state-selections',
        component: () => import('@/views/all-state/selections/SelectionsList.vue'),
        meta: {
            pageTitle: 'Selections',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Selections',
                    active: true,
                },
            ],
            subject: 'all-state-selections',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/all-state/selections/:slug',
        name: 'all-state-selection',
        props: true,
        component: () => import('@/views/all-state/selections/SelectionsTable.vue'),
        meta: {
            pageTitle: 'Selection',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Selections',
                    to: { name: 'all-state-selections' }
                },
                {
                    text: 'Selection',
                    active: true,
                },
            ],
            subject: 'all-state-selection',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/all-state/selections/:slug/print',
        name: 'all-state-selection-print',
        props: true,
        component: () => import('@/views/all-state/selections/SelectionsPrintGroups.vue'),
        meta: {
            pageTitle: 'Selection',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Selections',
                    to: { name: 'all-state-selections' }
                },
                {
                    text: 'Selection',
                    active: false,
                },
                {
                    text: 'Print',
                    active: true,
                },
            ],
            subject: 'all-state-selection',
            action: 'read',
            hasSettings: false
        },
    },
/*    {
        path: '/all-state/selections/:slug/acceptance',
        name: 'all-state-selection-acceptance',
        props: true,
        component: () => import('@/views/all-state/selections/SelectionAcceptance.vue'),
        meta: {
            pageTitle: 'Selection Acceptance',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Selections',
                    to: { name: 'all-state-selections' }
                },
                {
                    text: 'Selection',
                    to: { name: 'all-state-selection' }
                },
                {
                    text: 'Acceptance',
                    active: true,
                },
            ],
            subject: 'all-state-selection-acceptance',
            action: 'read',
            hasSettings: false
        },
    },*/

    {
        path: '/all-state/acceptance',
        name: 'all-state-acceptance',
        component: () => import('@/views/all-state/acceptance/AcceptanceList.vue'),
        meta: {
            pageTitle: 'Acceptance',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Acceptance',
                    active: true,
                },
            ],
            subject: 'all-state-acceptance',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/all-state/acceptance/:slug',
        name: 'all-state-acceptance-table',
        props: true,
        component: () => import('@/views/all-state/acceptance/AcceptanceTable.vue'),
        meta: {
            pageTitle: 'Ensemble',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Acceptance',
                    to: { name: 'all-state-acceptance' }
                },
                {
                    text: 'Ensemble',
                    active: true,
                },
            ],
            subject: 'all-state-acceptance',
            action: 'read',
            hasSettings: false
        },
    },

    /*{
        path: '/all-state/printable/badges/conference',
        name: 'all-state-printable-badges-conference',
        component: () => import('@/views/all-state/printable/badges/Conference.vue'),
        meta: {
            pageTitle: 'Conference Badges',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Badges',
                    active: true,
                },
                {
                    text: 'Conference',
                    active: true,
                },
            ],
            subject: 'all-state-printable-badges',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/all-state/printable/badges/albany',
        name: 'all-state-printable-badges-albany',
        component: () => import('@/views/all-state/printable/badges/Albany.vue'),
        meta: {
            pageTitle: 'Conference Badges',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Badges',
                    active: true,
                },
                {
                    text: 'Albany',
                    active: true,
                },
            ],
            subject: 'all-state-printable-badges',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/all-state/printable/labels',
        name: 'all-state-printable-labels',
        component: () => import('@/views/all-state/printable/labels/Labels.vue'),
        meta: {
            pageTitle: 'Labels',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Labels',
                    active: true,
                },
            ],
            subject: 'all-state-printable-labels',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/all-state/printable/certificates',
        name: 'all-state-printable-certificates',
        component: () => import('@/views/all-state/printable/certificates/Certificates.vue'),
        meta: {
            pageTitle: 'Certificates',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Certificates',
                    active: true,
                },
            ],
            subject: 'all-state-printable-certificates',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/all-state/printable/magazine-and-program',
        name: 'all-state-printable-magazine-and-program',
        component: () => import('@/views/all-state/printable/MagazineAndProgram.vue'),
        meta: {
            pageTitle: 'School Music News for Magazine and Program',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Magazine and Program Data',
                    active: true,
                },
            ],
            subject: 'all-state-printable-magazine-and-program',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/all-state/printable/letters/teacher',
        name: 'all-state-printable-letters-teacher',
        component: () => import('@/views/all-state/printable/letters/Teacher.vue'),
        meta: {
            pageTitle: 'Teacher Letter',
            breadcrumb: [
                {
                    text: 'All-State',
                },
                {
                    text: 'Printable',
                    active: true,
                },
                {
                    text: 'Letters',
                    active: true,
                },
                {
                    text: 'Teacher',
                    active: true,
                },
            ],
            subject: 'all-state-printable-teacher-letter',
            action: 'read',
            hasSettings: false
        },
    },*/
]
