import { API, graphqlOperation } from 'aws-amplify';
import store from '@/store';

export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            schools {
                items {
                    schoolID
                }
            }
            ensembleIds
            instrumentIds
            zoneIds
            avatar {
                size
                name
                src
            }
        }
    }
`;



// eslint-disable-next-line import/prefer-default-export
export async function setAccount(account) {
    //console.log('setAccountFunc', account)
    let response; let user;
    if(account.id) {
        response = await API.graphql(graphqlOperation(getUser, { id: account.id })).catch(e => { console.debug(e) })
        user = response.data.getUser
    }

    if(user) {
        const mappedUser = {
            id: user.id,
            username: user.username,
            groups: account.groups ? account.groups : [],
            zoneIds: user.zoneIds ? user.zoneIds : [],
            ensembleIds: user.ensembleIds ? user.ensembleIds : [],
            instrumentIds: user.instrumentIds ? user.instrumentIds : [],
            schoolIds: user.schools?.items ? user?.schools?.items.map(item => item.schoolID) : [],
            avatar: {
                size: user.avatar?.size ? user.avatar.size : 0,
                name: user.avatar?.name ? user.avatar.name : null,
                src: user.avatar?.src ? user.avatar.src : null,
            },
        }
        await store.dispatch('account/setAccount', mappedUser)
    }
    else {
        await store.dispatch('account/setAccount', account)
    }
}


