export default [
  /** Management **/
  {
    path: '/management/zones',
    name: 'management-zones',
    component: () => import('@/views/management/zones/Zones.vue'),
    meta: {
      pageTitle: 'Zones',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Zones',
          active: true,
        },
      ],
      subject: 'management-zones',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/zones/:id',
    name: 'management-zone',
    props: true,
    component: () => import('@/views/management/zones/Zone.vue'),
    meta: {
      pageTitle: 'Zone',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Zones',
          to: { name: 'management-zones' }
        },
        {
          text: 'Zone',
          active: true,
        },
      ],
      subject: 'management-zone',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/districts',
    name: 'management-districts',
    component: () => import('@/views/management/districts/Districts.vue'),
    meta: {
      pageTitle: 'Districts',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Districts',
          active: true,
        },
      ],
      subject: 'management-districts',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/districts/:slug',
    name: 'management-district',
    props: true,
    component: () => import('@/views/management/districts/District.vue'),
    meta: {
      pageTitle: 'District',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Districts',
          to: { name: 'management-districts' }
        },
        {
          text: 'District',
          active: true,
        },
      ],
      subject: 'management-district',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/schools',
    name: 'management-schools',
    component: () => import('@/views/management/schools/Schools.vue'),
    meta: {
      pageTitle: 'Schools',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Schools',
          active: true,
        },
      ],
      subject: 'management-schools',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/schools/:slug',
    name: 'management-school',
    props: true,
    component: () => import('@/views/management/schools/School.vue'),
    meta: {
      pageTitle: 'School',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Schools',
          to: { name: 'management-schools' }
        },
        {
          text: 'School',
          active: true,
        },
      ],
      subject: 'management-school',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/festivals',
    name: 'management-festivals',
    component: () => import('@/views/management/festivals/Festivals.vue'),
    meta: {
      pageTitle: 'Festivals',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Festivals',
          active: true,
        },
      ],
      subject: 'management-festivals',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/festivals/:id',
    name: 'management-festival',
    props: true,
    component: () => import('@/views/management/festivals/Festival.vue'),
    meta: {
      pageTitle: 'Festival',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Festivals',
          to: { name: 'management-festivals' }
        },
        {
          text: 'Festival',
          active: true,
        },
      ],
      subject: 'management-festival',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/forms',
    name: 'management-forms',
    component: () => import('@/views/management/forms/Forms.vue'),
    meta: {
      pageTitle: 'Forms',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Forms',
          active: true,
        },
      ],
      subject: 'management-forms',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/forms/:slug',
    name: 'management-form',
    props: true,
    component: () => import('@/views/management/forms/Form.vue'),
    meta: {
      pageTitle: 'Form',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Forms',
          to: { name: 'management-forms' }
        },
        {
          text: 'Form',
          active: true,
        },
      ],
      subject: 'management-form',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/ensembles',
    name: 'management-ensembles',
    component: () => import('@/views/management/ensembles/Ensembles.vue'),
    meta: {
      pageTitle: 'Ensembles',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Ensembles',
          active: true,
        },
      ],
      subject: 'management-ensembles',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/ensembles/:slug',
    name: 'management-ensemble',
    props: true,
    component: () => import('@/views/management/ensembles/Ensemble.vue'),
    meta: {
      pageTitle: 'Ensemble',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Ensembles',
          to: { name: 'management-ensembles' }
        },
        {
          text: 'Ensemble',
          active: true,
        },
      ],
      subject: 'management-ensemble',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/instruments',
    name: 'management-instruments',
    component: () => import('@/views/management/instruments/Instruments.vue'),
    meta: {
      pageTitle: 'Instruments',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Instruments',
          active: true,
        },
      ],
      subject: 'management-instruments',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/instruments/:slug',
    name: 'management-instrument',
    props: true,
    component: () => import('@/views/management/instruments/Instrument.vue'),
    meta: {
      pageTitle: 'Instrument',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Instruments',
          to: { name: 'management-instruments' }
        },
        {
          text: 'Instrument',
          active: true,
        },
      ],
      subject: 'management-instrument',
      action: 'read',
      hasSettings: false
    },
  },
  {
    path: '/management/users',
    name: 'management-users',
    component: () => import('@/views/management/users/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Users',
          active: true,
        },
      ],
      subject: 'management-users',
      action: 'read',
      hasSettings: true
    },
  },
  {
    path: '/management/users/:username',
    name: 'management-user',
    props: true,
    component: () => import('@/views/management/users/User.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Management',
        },
        {
          text: 'Users',
          to: { name: 'management-users' }
        },
        {
          text: 'User',
          active: true,
        },
      ],
      subject: 'management-users',
      action: 'read',
      hasSettings: false
    },
  },

  {
    path: '/management/settings',
    name: 'management-settings-kv',
    component: () => import('@/views/management/settings/SettingKeyValues.vue'),
    meta: {
      subject: 'management-settings-kv',
      action: 'dev',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      hasSettings: false
    },
  },
  {
    path: '/management/settings/app',
    name: 'management-settings-app',
    component: () => import('@/views/management/settings/SettingsApp.vue'),
    meta: {
      subject: 'management-settings-app',
      action: 'read',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      hasSettings: false
    },
  },
  {
    path: '/management/settings/application',
    name: 'management-settings-application',
    component: () => import('@/views/management/settings/SettingsApplication.vue'),
    meta: {
      subject: 'management-settings-application',
      action: 'read',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      hasSettings: false
    },
  },
  {
    path: '/management/settings/selection',
    name: 'management-settings-selection',
    component: () => import('@/views/management/settings/SettingsSelection.vue'),
    meta: {
      subject: 'management-settings-selection',
      action: 'read',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      hasSettings: false
    },
  },
  {
    path: '/management/settings/billing',
    name: 'management-settings-billing',
    component: () => import('@/views/management/settings/SettingsBilling.vue'),
    meta: {
      subject: 'management-settings-billing',
      action: 'read',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      hasSettings: false
    },
  },
  {
    path: '/management/settings/support',
    name: 'management-settings-support',
    component: () => import('@/views/management/settings/SettingsSupport.vue'),
    meta: {
      subject: 'management-settings-support',
      action: 'read',
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      hasSettings: false
    },
  },

]
