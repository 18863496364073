// noinspection DuplicatedCode,JSCheckFunctionSignatures
// noinspection DuplicatedCode

/* eslint-disable no-else-return, import/prefer-default-export */


import { AbilityBuilder, Ability } from '@casl/ability'

const ROLE = {
  ADMIN: 'Admin',
  NYSSMA_ADMIN: 'NYSSMA_Admin',
  NYSSMA_STAFF: 'NYSSMA_Staff',
  NYSSMA_COMMITTEE: 'NYSSMA_Committee',
  NYSSMA_CHAIR: 'NYSSMA_Chair',
  TEACHER: 'Teacher',
  CHAPERONE: 'Chaperone',
  ZONE_REP: 'ZoneRep'
}

const ACTION = {
  MANAGE: 'manage',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  ENABLE: 'enable',
  LOCK: 'lock',
  HIDE: 'hide',
  QUERY: 'query',
  TICKET_EDIT: 'ticket.edit',
  TICKET_DELETE: 'ticket.delete',
  TICKET_DELETE_COMMENT: 'ticket.delete.comment',
  TICKET_HIDE: 'ticket.hide',
  TICKET_LOCK: 'ticket.lock',
  TICKET_LABEL: 'ticket.label',
  TICKET_STATUS: 'ticket.status',
  CHANGE_YEAR: 'change.year',
  DEBUG: 'debug',
  DEV: 'dev'
}

const SUBJECTS = {

  ALL: {
    subject: 'all'
  },
  AUTH: {
    REGISTER: {
      subject: 'auth-register'
    },
    LOGIN: {
      subject: 'auth-login'
    },
    FORGOT_PASSWORD: {
      subject: 'auth-forgot-password'
    }
  },
  DASHBOARD: {
    subject: 'dashboard',
    QUERIES: {
      listFestivals: 'listFestivals',
      listForms: 'listForms',
      listInstruments: 'listInstruments',
      listZones: 'listZones',
      listEnsembles: 'listEnsembles',
      listSelections: 'listSelections',
      listApplications: 'listApplications',
      listApplicationsByTeacher: 'listApplicationsByTeacher',
    },
    STATS: {
      festivals: 'festivals-stat',
      forms: 'forms-stat',
      instruments: 'instruments-stat',
      ensembles: 'ensembles-stat',
      applications: 'applications-stat',
      students: 'students-stat',
      selections: 'selections-stat',
    },
    TABLE: {
      applications: 'applications-table',
      selections: 'selections-table',
      ensembles: 'ensembles-table'
    },
    CHARTS: {
      scoredAndSelected: 'scored-and-selected-chart',
      scoredAndSelectedInstruments: 'scored-and-selected-instruments-chart',
      acceptedAndDeclined: 'accepted-and-declined-chart'
    }
  },
  ALL_STATE: {
    subject: 'all-state',
    APPLICATIONS: {
      subject: 'all-state-applications'
    },
    APPLICATION: {
      subject: 'all-state-application',
      TAB: {
        DETAILS: {
          subject: 'all-state-application[tab=details]'
        },
        STUDENT: {
          subject: 'all-state-application[tab=student]',
          FIELDS: {
            info: 'info',
            address: 'address',
            contact: 'contact'
          }
        },
        QUESTIONS: {
          subject: 'all-state-application[tab=questions]'
        },
        GRADING: {
          subject: 'all-state-application[tab=grading]'
        }
      }
    },
    APPLICATION_CREATE: {
      subject: 'all-state-application-create'
    },
    STUDENTS: {
      subject: 'all-state-students'
    },
    STUDENT: {
      subject: 'all-state-student',
      fields: {
        NAME_PREFERRED: 'name.preferred',
        INVOICE: 'invoice',
      }
    },
    SCORES: {
      subject: 'all-state-scores',
      fields: {
        LOCAL_RANK: 'local-rank',
        SCORE: 'score'
      }
    },
    SELECTIONS: {
      subject: 'all-state-selections'
    },
    SELECTION: {
      subject: 'all-state-selection',
      fields: {
        STATE_RANK: 'state-rank',
        ENSEMBLE: 'ensemble',
        PART: 'part'
      }
    },
    SELECTION_ACCEPTANCE: {
      subject: 'all-state-selection-acceptance',
      fields: {
        ENSEMBLE: 'ensemble',
        PART: 'part'
      }
    },
    ACCEPTANCE: {
      subject: 'all-state-acceptance',
    },
    BILLING: {
      subject: 'all-state-billing',
      BILLS: {
        subject: 'all-state-bills'
      },
      /*PURCHASE_ORDERS: {
        subject: 'all-state-purchase-orders'
      }*/
    },
  },
  REPORTS: {
    subject: 'reports',
    APPLICATION_RECOMMENDATION: {
      subject: 'reports-application-recommendation'
    },
    STATE_RANKING: {
      subject: 'reports-state-ranking'
    },
    PART_ASSIGNMENT: {
      subject: 'reports-part-assignment'
    },
    ENSEMBLE_ROSTER: {
      subject: 'reports-ensemble-roster'
    },
    FESTIVAL_APPLICATIONS: {
      subject: 'reports-festival-applications'
    },
    MULTIPLE_SELECTION_ACCEPTANCE: {
      subject: 'reports-multiple-selection-acceptance'
    },
    EVENTS_ROSTER: {
      subject: 'reports-events-roster'
    },
    BUS_ROSTER: {
      subject: 'reports-bus-roster'
    },
  },
  EVENTS: {
    subject: 'events',
    BADGES: {
      subject: 'events-badges'
    },
    BUSES: {
      subject: 'events-buses'
    },
    CHAPERONES: {
      subject: 'events-chaperones'
    },
    HOTELS: {
      subject: 'events-hotels'
    },
    ROOM_ASSIGNMENT: {
      subject: 'events-room-assignment'
    },
    STUDENTS: {
      subject: 'events-students'
    },
  },
  PRINTABLE: {
    subject: 'printable',
    /*BADGES: {
      subject: 'printable-badges'
    },*/
    LABELS: {
      subject: 'printable-labels'
    },
    CERTIFICATES: {
      subject: 'printable-certificates'
    },
    LETTERS: {
      subject: 'printable-letters'
    },
    MAGAZINE: {
      subject: 'printable-magazine-and-program'
    }
  },
  MANAGEMENT: {
    subject: 'management',

    INVOICES: { subject: 'management-invoices' },
    INVOICE: { subject: 'management-invoice' },
    /*PURCHASE_ORDERS: { subject: 'management-purchase-orders' },
    PURCHASE_ORDER: { subject: 'management-purchase-order' },*/

    ZONES: {
      subject: 'management-zones'
    },
    ZONE: {
      subject: 'management-zone'
    },
    FESTIVALS: {
      subject: 'management-festivals'
    },
    FESTIVAL: {
      subject: 'management-festival'
    },
    FORMS: {
      subject: 'management-forms'
    },
    FORM: {
      subject: 'management-form'
    },
    INSTRUMENTS: {
      subject: 'management-instruments'
    },
    INSTRUMENT: {
      subject: 'management-instrument'
    },
    ENSEMBLES: {
      subject: 'management-ensembles'
    },
    ENSEMBLE: {
      subject: 'management-ensemble'
    },
    DISTRICTS: {
      subject: 'management-districts'
    },
    DISTRICT: {
      subject: 'management-district'
    },
    SCHOOLS: {
      subject: 'management-schools'
    },
    SCHOOL: {
      subject: 'management-school'
    },
    USERS: {
      subject: 'management-users'
    },
    USER: {
      subject: 'management-user'
    },
    SETTINGS: {
      subject: 'management-settings',
      APPLICATIONS: {
        subject: 'management-settings-application',
      },
      BILLING: {
        subject: 'management-settings-billing',
      },
      LETTERS: {
        subject: 'management-settings-letters',
      },
      CERTIFICATES: {
        subject: 'management-settings-certificates',
      }
    }
  },
  SUPPORT: {
    subject: 'support',
    DOCS: {
      subject: 'support-docs'
    },
    TICKETS: {
      subject: 'support-tickets'
    },
    TICKETS_STATUS: {
      subject: 'support-tickets-status'
    },
    TICKETS_LABEL: {
      subject: 'support-tickets-label'
    },
    TICKET: {
      subject: 'support-ticket'
    }
  },
  DEBUG: {
    subject: 'debug'
  },
  DEV: {
    subject: 'dev'
  },
}

export function getAbilities(user) {
  const { rules, can } = new AbilityBuilder();
  publicAbilities(can)
  let ability = new Ability(rules)

  if(user) {
    const roles = sortRoles(user.signInUserSession.accessToken.payload['cognito:groups'])
    const roleRules = []

    const addToRules = (abilityRules) => {
      abilityRules.forEach(abilityRule => {
        const index = roleRules.findIndex(roleRule => roleRule.subject === abilityRule.subject)
        if(index > -1) {
          roleRules.splice(index, 1, abilityRule)
        }
        else {
          roleRules.push(abilityRule)
        }
      })
    }

    roles.forEach(role => {
      switch (role) {
        case ROLE.ZONE_REP: addToRules(zoneRepAbilities().rules); break
        case ROLE.CHAPERONE: addToRules(chaperoneAbilities().rules); break
        case ROLE.TEACHER: addToRules(teacherAbilities().rules); break
        case ROLE.NYSSMA_CHAIR: addToRules(nyssmaChairAbilities().rules); break
        case ROLE.NYSSMA_COMMITTEE: addToRules(nyssmaCommitteeAbilities().rules); break
        case ROLE.NYSSMA_STAFF: addToRules(nyssmaStaffAbilities().rules); break
        case ROLE.NYSSMA_ADMIN: addToRules(nyssmaAdminAbilities().rules); break
        case ROLE.ADMIN: addToRules(adminAbilities().rules); break
        default: break
      }
    })
    ability = new Ability(roleRules)
    //console.log('ability', ability)
  }
  return ability
}

function sortRoles(groups) {
  const roles = []
  groups.forEach(group => {
    switch (group) {
      case ROLE.ZONE_REP: roles.push({ name: group, order: 6 }); break
      case ROLE.CHAPERONE: roles.push({ name: group, order: 6 }); break
      case ROLE.TEACHER: roles.push({ name: group, order: 5 }); break
      case ROLE.NYSSMA_CHAIR: roles.push({ name: group, order: 5 }); break
      case ROLE.NYSSMA_COMMITTEE: roles.push({ name: group, order: 4 }); break
      case ROLE.NYSSMA_STAFF: roles.push({ name: group, order: 3 }); break
      case ROLE.NYSSMA_ADMIN: roles.push({ name: group, order: 2 }); break
      case ROLE.ADMIN: roles.push({ name: group, order: 1 }); break
      default: break
    }
  })
  return roles.sort((a, b) => b.order - a.order).map(role => role.name)
}

function adminAbilities() {
  const { rules, can } = new AbilityBuilder();
  can(ACTION.MANAGE, SUBJECTS.ALL.subject)
  return new Ability(rules)
}
function nyssmaAdminAbilities() {
  const { rules, can, cannot } = new AbilityBuilder();
  can(ACTION.MANAGE, SUBJECTS.ALL.subject)
  cannot(ACTION.MANAGE, SUBJECTS.DEBUG.subject)
  cannot(ACTION.MANAGE, SUBJECTS.DEV.subject)
  return new Ability(rules)
}
function nyssmaStaffAbilities() {
  const { rules, can } = new AbilityBuilder();
  can(ACTION.CHANGE_YEAR, SUBJECTS.ALL.subject)
  can(
      ACTION.READ,
      SUBJECTS.DASHBOARD.subject,
      [
        SUBJECTS.DASHBOARD.QUERIES.listFestivals,
        SUBJECTS.DASHBOARD.QUERIES.listInstruments,
        SUBJECTS.DASHBOARD.QUERIES.listZones,
        SUBJECTS.DASHBOARD.QUERIES.listApplications,

        SUBJECTS.DASHBOARD.STATS.applications,
        SUBJECTS.DASHBOARD.STATS.students,
        SUBJECTS.DASHBOARD.STATS.festivals,
        SUBJECTS.DASHBOARD.STATS.instruments,

        SUBJECTS.DASHBOARD.TABLE.applications,

        SUBJECTS.DASHBOARD.CHARTS.scoredAndSelected,
        SUBJECTS.DASHBOARD.CHARTS.scoredAndSelectedInstruments,
      ]
  )
  /** ALL-STATE **/
  can(ACTION.READ, SUBJECTS.ALL_STATE.subject)

  // Applications
  can([ACTION.READ, ACTION.DELETE], SUBJECTS.ALL_STATE.APPLICATIONS.subject)

  //Application
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.APPLICATION.TAB.DETAILS.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.STUDENT.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.QUESTIONS.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.GRADING.subject)

  //Students
  can([ACTION.READ], SUBJECTS.ALL_STATE.STUDENTS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.STUDENT.subject)

  //Scores
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.SCORES.subject, [SUBJECTS.ALL_STATE.SCORES.fields.LOCAL_RANK, SUBJECTS.ALL_STATE.SCORES.fields.SCORE])

  //Selections
  can(ACTION.READ, SUBJECTS.ALL_STATE.SELECTIONS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.SELECTION.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.ACCEPTANCE.subject)

  /** REPORTS **/
  can(ACTION.READ, SUBJECTS.REPORTS.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.APPLICATION_RECOMMENDATION.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.STATE_RANKING.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.PART_ASSIGNMENT.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.ENSEMBLE_ROSTER.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.EVENTS_ROSTER.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.BUS_ROSTER.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.FESTIVAL_APPLICATIONS.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.MULTIPLE_SELECTION_ACCEPTANCE.subject)

  /** Events **/
  can(ACTION.READ, SUBJECTS.EVENTS.subject)
  can([ACTION.READ, ACTION.CREATE, ACTION.UPDATE, ACTION.DELETE], SUBJECTS.EVENTS.BUSES.subject)
  can([ACTION.READ, ACTION.UPDATE, ACTION.DELETE], SUBJECTS.EVENTS.BADGES.subject)


  /** Printable **/
  can(ACTION.READ, SUBJECTS.PRINTABLE.subject)
  //can(ACTION.READ, SUBJECTS.PRINTABLE.BADGES.subject)
  can(ACTION.READ, SUBJECTS.PRINTABLE.CERTIFICATES.subject)
  can(ACTION.READ, SUBJECTS.PRINTABLE.LETTERS.subject)
  can(ACTION.READ, SUBJECTS.PRINTABLE.LABELS.subject)
  can(ACTION.READ, SUBJECTS.PRINTABLE.MAGAZINE.subject)


  /** MANAGEMENT **/
  can(ACTION.READ, SUBJECTS.MANAGEMENT.subject)
  can(ACTION.READ, SUBJECTS.MANAGEMENT.INVOICES.subject)
  can(ACTION.READ, SUBJECTS.MANAGEMENT.INVOICE.subject)
  /*can(ACTION.READ, SUBJECTS.MANAGEMENT.PURCHASE_ORDERS.subject)
  can(ACTION.READ, SUBJECTS.MANAGEMENT.PURCHASE_ORDER.subject)*/

  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.ZONES.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.ZONE.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.FESTIVALS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.FESTIVAL.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.FORMS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.FORM.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.INSTRUMENTS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.INSTRUMENT.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.ENSEMBLES.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.ENSEMBLE.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.DISTRICTS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.DISTRICT.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.SCHOOLS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.SCHOOL.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.SETTINGS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.MANAGEMENT.SETTINGS.BILLING.subject)

  publicAbilities(can)
  loggedInAbilities(can)
  supportManagementAbilities(can)
  return new Ability(rules)
}

function nyssmaChairAbilities() {
  const { rules, can } = new AbilityBuilder();
  can(
      ACTION.READ,
      SUBJECTS.DASHBOARD.subject,
      [
        SUBJECTS.DASHBOARD.QUERIES.listFestivals,
        SUBJECTS.DASHBOARD.QUERIES.listInstruments,
        SUBJECTS.DASHBOARD.QUERIES.listEnsembles,
        SUBJECTS.DASHBOARD.QUERIES.listSelections,
        SUBJECTS.DASHBOARD.QUERIES.listApplications,

        SUBJECTS.DASHBOARD.STATS.applications,
        SUBJECTS.DASHBOARD.STATS.students,
        SUBJECTS.DASHBOARD.STATS.selections,
        SUBJECTS.DASHBOARD.STATS.festivals,
        SUBJECTS.DASHBOARD.STATS.instruments,
        SUBJECTS.DASHBOARD.STATS.ensembles,

        SUBJECTS.DASHBOARD.TABLE.selections,
        SUBJECTS.DASHBOARD.TABLE.ensembles,

        SUBJECTS.DASHBOARD.CHARTS.acceptedAndDeclined,
      ]
  )
  /** ALL-STATE **/
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.DETAILS.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.STUDENT.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.QUESTIONS.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.GRADING.subject)

  can(ACTION.READ, SUBJECTS.ALL_STATE.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.SELECTIONS.subject)
  can(
      [ACTION.READ, ACTION.UPDATE],
      SUBJECTS.ALL_STATE.SELECTION.subject,
      [
        SUBJECTS.ALL_STATE.SELECTION.fields.ENSEMBLE,
        SUBJECTS.ALL_STATE.SELECTION.fields.PART
      ]
  )
  can(
      [ACTION.READ, ACTION.UPDATE],
      SUBJECTS.ALL_STATE.ACCEPTANCE.subject
  )

  /** REPORTS **/
  can(ACTION.READ, SUBJECTS.REPORTS.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.APPLICATION_RECOMMENDATION.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.STATE_RANKING.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.ENSEMBLE_ROSTER.subject)

  publicAbilities(can)
  loggedInAbilities(can)
  supportUserAbilities(can)
  return new Ability(rules)
}

function nyssmaCommitteeAbilities() {
  const { rules, can } = new AbilityBuilder();
  can(
      ACTION.READ,
      SUBJECTS.DASHBOARD.subject,
      [
        SUBJECTS.DASHBOARD.QUERIES.listFestivals,
        SUBJECTS.DASHBOARD.QUERIES.listInstruments,
        SUBJECTS.DASHBOARD.QUERIES.listEnsembles,
        SUBJECTS.DASHBOARD.QUERIES.listSelections,
        SUBJECTS.DASHBOARD.QUERIES.listApplications,

        SUBJECTS.DASHBOARD.STATS.applications,
        SUBJECTS.DASHBOARD.STATS.students,
        SUBJECTS.DASHBOARD.STATS.selections,
        SUBJECTS.DASHBOARD.STATS.festivals,
        SUBJECTS.DASHBOARD.STATS.instruments,
        SUBJECTS.DASHBOARD.STATS.ensembles,

        SUBJECTS.DASHBOARD.TABLE.selections,
        SUBJECTS.DASHBOARD.TABLE.ensembles,

        SUBJECTS.DASHBOARD.CHARTS.acceptedAndDeclined,
      ]
  )
  /** ALL-STATE **/
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.DETAILS.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.STUDENT.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.QUESTIONS.subject)
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.TAB.GRADING.subject)

  can(ACTION.READ, SUBJECTS.ALL_STATE.subject)
  can(
      ACTION.READ,
      SUBJECTS.ALL_STATE.SELECTIONS.subject
  )
  can(
      [ACTION.READ],
      SUBJECTS.ALL_STATE.SELECTION.subject,
      [
        SUBJECTS.ALL_STATE.SELECTION.fields.ENSEMBLE,
        SUBJECTS.ALL_STATE.SELECTION.fields.PART
      ]
  )


  publicAbilities(can)
  loggedInAbilities(can)
  supportUserAbilities(can)
  return new Ability(rules)
}

function teacherAbilities() {
  const { rules, can } = new AbilityBuilder();
  can(
      ACTION.READ,
      SUBJECTS.DASHBOARD.subject,
      [
        SUBJECTS.DASHBOARD.QUERIES.listFestivals,
        SUBJECTS.DASHBOARD.QUERIES.listInstruments,
        SUBJECTS.DASHBOARD.QUERIES.listEnsembles,
        // SUBJECTS.DASHBOARD.QUERIES.listSelections,
        SUBJECTS.DASHBOARD.QUERIES.listApplicationsByTeacher,

        SUBJECTS.DASHBOARD.STATS.applications,
        SUBJECTS.DASHBOARD.STATS.students,
        // SUBJECTS.DASHBOARD.STATS.selections,
        SUBJECTS.DASHBOARD.STATS.festivals,
        SUBJECTS.DASHBOARD.STATS.instruments,


        SUBJECTS.DASHBOARD.TABLE.applications,
        // SUBJECTS.DASHBOARD.TABLE.selections,

        // SUBJECTS.DASHBOARD.CHARTS.scoredAndSelected,
      ]
  )

  /** ALL-STATE **/
  //Applications
  can(ACTION.READ, SUBJECTS.ALL_STATE.subject)
  can([ACTION.READ, ACTION.CREATE, ACTION.DELETE], SUBJECTS.ALL_STATE.APPLICATIONS.subject)

  //Application
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.APPLICATION.TAB.DETAILS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.APPLICATION.TAB.STUDENT.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.APPLICATION.TAB.QUESTIONS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.APPLICATION.TAB.GRADING.subject)

  //Application - Create
  can(ACTION.READ, SUBJECTS.ALL_STATE.APPLICATION_CREATE.subject)

  //Students
  can([ACTION.READ], SUBJECTS.ALL_STATE.STUDENTS.subject)

  //Student
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.ALL_STATE.STUDENT.subject, [''])

  publicAbilities(can)
  loggedInAbilities(can)
  supportUserAbilities(can)
  return new Ability(rules)
}

function chaperoneAbilities() {
  const { rules, can } = new AbilityBuilder();
  can(ACTION.READ, SUBJECTS.DASHBOARD.subject, ['none'])
  can(ACTION.READ, SUBJECTS.EVENTS.subject)
  can(ACTION.READ, SUBJECTS.EVENTS.BADGES.subject)
  can([ACTION.READ, ACTION.CREATE, ACTION.UPDATE, ACTION.DELETE], SUBJECTS.EVENTS.BUSES.subject)
  can([ACTION.READ, ACTION.CREATE, ACTION.UPDATE, ACTION.DELETE], SUBJECTS.EVENTS.CHAPERONES.subject)
  can([ACTION.READ, ACTION.CREATE, ACTION.UPDATE, ACTION.DELETE], SUBJECTS.EVENTS.HOTELS.subject)
  can(ACTION.READ, SUBJECTS.EVENTS.ROOM_ASSIGNMENT.subject)
  can([ACTION.READ, ACTION.UPDATE, ACTION.DELETE], SUBJECTS.EVENTS.STUDENTS.subject)

  can(ACTION.READ, SUBJECTS.REPORTS.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.EVENTS_ROSTER.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.BUS_ROSTER.subject)

  publicAbilities(can)
  loggedInAbilities(can)
  supportUserAbilities(can)
  return new Ability(rules)
}

function zoneRepAbilities() {
  const { rules, can } = new AbilityBuilder();
  can(
      ACTION.READ,
      SUBJECTS.DASHBOARD.subject,
      [
        SUBJECTS.DASHBOARD.QUERIES.listFestivals,
        SUBJECTS.DASHBOARD.QUERIES.listInstruments,
        SUBJECTS.DASHBOARD.QUERIES.listApplications,

        SUBJECTS.DASHBOARD.STATS.applications,
        SUBJECTS.DASHBOARD.STATS.students,
        SUBJECTS.DASHBOARD.STATS.festivals,
        SUBJECTS.DASHBOARD.STATS.instruments,

        SUBJECTS.DASHBOARD.TABLE.applications,
      ]
  )
  can(ACTION.READ, SUBJECTS.REPORTS.subject)
  can(ACTION.READ, SUBJECTS.REPORTS.FESTIVAL_APPLICATIONS.subject)

  publicAbilities(can)
  loggedInAbilities(can)
  supportUserAbilities(can)
  return new Ability(rules)
}

function publicAbilities(can) {
  can(ACTION.READ, 'home')
  can(ACTION.READ, 'auth-login')
  can(ACTION.READ, 'auth-forgot-password')
  can(ACTION.READ, 'forbidden')
  can(ACTION.READ, 'notFound')
  can(ACTION.READ, 'error')
}

function loggedInAbilities(can) {
  can([ACTION.READ, ACTION.UPDATE], 'account-settings')
}

function supportUserAbilities(can) {
  can(ACTION.READ, SUBJECTS.SUPPORT.subject)
  can(ACTION.READ, SUBJECTS.SUPPORT.DOCS.subject)
  can([ACTION.READ, ACTION.CREATE], SUBJECTS.SUPPORT.TICKETS.subject)
  can([ACTION.READ, ACTION.UPDATE], SUBJECTS.SUPPORT.TICKETS_STATUS.subject)
  can([ACTION.READ, ACTION.TICKET_EDIT, ACTION.TICKET_LABEL, ACTION.TICKET_DELETE_COMMENT], SUBJECTS.SUPPORT.TICKET.subject)
}

function supportManagementAbilities(can) {
  can(ACTION.MANAGE, SUBJECTS.SUPPORT.subject)
  can(ACTION.MANAGE, SUBJECTS.SUPPORT.DOCS.subject)
  can(ACTION.MANAGE, SUBJECTS.SUPPORT.TICKETS.subject)
  can(ACTION.MANAGE, SUBJECTS.SUPPORT.TICKETS_STATUS.subject)
  can(ACTION.MANAGE, SUBJECTS.SUPPORT.TICKET.subject)
}
