export default {
  namespaced: true,
  state: {
    title: null,
    breadcrumbs: [],
    menu: []
  },
  getters: {},
  mutations: {
    UPDATE_TITLE(state, val) {
      state.title = val
    },
    UPDATE_BREADCRUMBS(state, val) {
      state.breadcrumbs = val
    },
    UPDATE_ACTIVE_BREADCRUMB_TEXT(state, val) {
      const active = state.breadcrumbs.find(breadcrumb => breadcrumb.active)
      if(active) {
        active.text = val
      }
    },
    UPDATE_MENU(state, val) {
      state.menu = val
    },
  },
  actions: {},
}
