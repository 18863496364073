import _ from 'lodash'
import store from '@/store'

export const DEFAULT_USERNAME = 'annon';

export default {
  namespaced: true,
  state: { },
  actions: {
    updateItem({commit}, payload) {
      commit('updateItem', payload)
      commit('reloadItems', payload)
    },
    removeItem({commit}, payload) {
      commit('removeItem', payload)
      commit('reloadItems', payload)
    },

    updateRoutes({commit}, payload) {
      commit('updateRoutes', payload)
      commit('reloadItems', payload)
    }
  },
  mutations: {
    updateItem(state, payload) {
      const lsRoutes = localStorage.getItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(lsRoutes) {
        const routes = JSON.parse(lsRoutes)
        if (routes) {
          const route = routes.find(r => r.name === payload.key)
          if (route?.enabled) {
            if (!payload.subKey) {
              localStorage.setItem(`nyssma.settings.${payload.key}.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`, JSON.stringify(payload.value))
            }
            else {
              const lsRouteSettings = localStorage.getItem(`nyssma.settings.${payload.key}.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
              if(lsRouteSettings) {
                const lsRoute = JSON.parse(lsRouteSettings)
                if(lsRoute) {
                  _.set(lsRoute, payload.subKey, payload.value)
                  localStorage.setItem(`nyssma.settings.${payload.key}.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`, JSON.stringify(lsRoute))
                }
              }
              else {
                const rs = { }
                _.set(rs, payload.subKey, payload.value)
                localStorage.setItem(`nyssma.settings.${payload.key}.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`, JSON.stringify(rs))
              }
            }
          }
        }
      }
    },
    removeItem(state, payload) {
      const lsRoutes = localStorage.getItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(lsRoutes) {
        const routes = JSON.parse(lsRoutes)
        if (routes) {
          const route = routes.find(r => r.name === payload.key)
          if (route) {
            localStorage.removeItem(`nyssma.settings.${payload.key}.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
          }
        }
      }
    },

    updateRoutes(state, payload) {
      localStorage.setItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`, JSON.stringify(payload.value))
    },
    reloadItems(state) {
      /** Reload Items - Very Important in order to make sure the **/
      /*state.items = Object.keys(localStorage)
          .filter(k => k.startsWith('nyssma.settings') && k.endsWith(store?.getters['account/getUserName'] || defaultUsername))
          .reduce((obj, k) => ({
            ...obj,
            [k]: JSON.parse(localStorage.getItem(k))
          }), {}) || []*/
    },

    clearAll() {
      const items = { ...localStorage };
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(localStorage)) {
        if(key.startsWith('nyssma.settings') && key.endsWith(`.${store?.getters['account/getUserName']}`)) {
          localStorage.removeItem(key);
        }
      }
    }
  },
  getters: {
    getItem: (state) => (key) => {
      const item = localStorage.getItem(`nyssma.settings.${key}.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      return item ? JSON.parse(item) : null
    },
    getSubItem: (state) => (key, path, defaultValue) => {
      const item = localStorage.getItem(`nyssma.settings.${key}.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(item) {
        try {
          const parsedItem = JSON.parse(item)
          return _.get(parsedItem, path, defaultValue)
        }
        catch(e) {
          console.error('getSubItem - Parse Error', e)
        }
      }
      return defaultValue
    },
    getRoutes(state) {
      const routes = localStorage.getItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(routes) {
        const item = JSON.parse(routes)
        if (item) {
          return item
        }
      }
      return null
    },
    isRouteDisabled: () => (key) => {
      const lsRoutes = localStorage.getItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(lsRoutes) {
        const routes = JSON.parse(lsRoutes)
        if (routes) {
          const route = routes.find(r => r.name === key)
          if(route) {
            return route?.enabled === false || route?.isLocalEnabled === false
          }
        }
      }
      return true;
    },
    isRouteEnabled: () => (key) => {
      const lsRoutes = localStorage.getItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(lsRoutes) {
        const routes = JSON.parse(lsRoutes)
        if (routes) {
          const route = routes.find(r => r.name === key)
          if(route) {
            return route.enabled === true
          }
        }
      }
      return false;
    },
    isRouteLocalEnabled: () => (key) => {
      const lsRoutes = localStorage.getItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(lsRoutes) {
        const routes = JSON.parse(lsRoutes)
        if (routes) {
          const route = routes.find(r => r.name === key)
          if(route) {
            return route.enabled === true && route.isLocalEnabled === true
          }
        }
      }
      return false;
    },
    isRouteCacheEnabled: () => (key) => {
      const lsRoutes = localStorage.getItem(`nyssma.settings.routes.${store?.getters['account/getUserName'] || DEFAULT_USERNAME}`)
      if(lsRoutes) {
        const routes = JSON.parse(lsRoutes)
        if (routes) {
          const route = routes.find(r => r.name === key)
          if(route) {
            return route.enabled === true && route.isCacheEnabled === true
          }
        }
      }
      return false;
    },
  }
}
