// eslint-disable-next-line import/prefer-default-export
export const account = {
  namespaced: true,
  state: {
    id: null,
    username: null,
    avatar: {
      name: null,
      size: 0,
      src: null
    },
    groups: [],
    zoneIds: [],
    ensembleIds: [],
    instrumentIds: [],
    schoolIds: [],
  },
  actions: {
    setAccount({ commit }, user) {
      commit('setAccount', user);
    },
    setAvatar({ commit }, avatar) {
      commit('setAvatar', avatar);
    },
    setYear({ commit }, year) {
      commit('setYear', year);
    },
    logout({ commit }) {
      commit('logout');
    }
  },
  mutations: {
    setAccount(state, user) {
      state.id = user?.id
      state.username = user?.username
      state.groups = user?.groups
      state.avatar = user?.avatar
      state.zoneIds = user?.zoneIds
      state.ensembleIds = user?.ensembleIds
      state.instrumentIds = user?.instrumentIds
      state.schoolIds = user?.schoolIds
    },
    setAvatar(state, avatar) {
      state.avatar = avatar
    },
    setYear(state, year) {
      state.year = year
    },
    logout(state) {
      state.id = null
      state.username = null
      state.avatar = {
        name: null,
        size: 0,
        src: null
      }
    }
  },
  getters: {
    getUserName: (state) => state.username
  }
};

