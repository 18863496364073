import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import auth from './routes/nyssma-auth'
import dashboard from './routes/dashboard'
import allState from './routes/nyssma-all-state'
import reports from './routes/nyssma-reports'
import printable from './routes/nyssma-printable'
import events from './routes/nyssma-events'
import management from './routes/nyssma-management'
import support from './routes/nyssma-support'
import billing from './routes/nyssma-billing'
import errors from './routes/nyssma-errors'
import dev from './routes/nyssma-dev'


import {Auth} from 'aws-amplify';
import ability from '@/libs/acl/ability';
import { getAbilities } from '@/libs/acl/roles2';
import { canNavigate } from '@/libs/acl/routeProtection'
import store from '@/store/index'
import {setAccount} from '@/scripts/account';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...auth,
    ...dashboard,
    ...allState,
    ...reports,
    ...printable,
    ...events,
    ...management,
    ...support,
    ...billing,
    ...dev,
    ...errors,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
})

router.beforeEach(async (to, from, next) => {
  if(to.name === from.name) { next() } //will stop re-authenticating when going to the same place (ie: a query param changed)
  else {
    let isLoggedIn = false;
    await Auth.currentAuthenticatedUser().then(async user => {
      isLoggedIn = true;
      await ability.update(getAbilities(user).rules) //roles-2

      if (!store.state.account.id) {
        const account = {
          id: user.attributes['custom:user_id'],
          username: user.username,
          groups: user.signInUserSession.accessToken.payload['cognito:groups']
        }
        await setAccount(account)
      }

      if(!store.getters['settings/hasSettingsApp']) {
        await store.dispatch('settings/loadSettingsApp')
      }

      //const sessionUser = sessionStorage.getItem('nyssma') ? JSON.parse(sessionStorage.getItem('nyssma')) : { account: { id: null, username: null } }
      //if(user.username !== sessionUser.account.username || user.attributes['custom:user_id'] !== sessionUser.account.id) {
      if(user.attributes['custom:user_id'] !== store.state.account.id) {
        await Auth.signOut().then(async () => {
          await sessionStorage.clear()
          await store.dispatch('all-state-applications/clear')
          await store.dispatch('all-state-scores/clear')
          await store.dispatch('account/logout')
          await ability.update(getAbilities(null).rules)
          isLoggedIn = false
        })
      }
    })
    .catch(async (e) => {
      console.error(e)
      await ability.update(getAbilities(null).rules)
    })
    .finally(() => {
      if (canNavigate(to)) {
        next()
      }
      else if(isLoggedIn) {
        next({ name: 'error-not-authorized' })
      }
      else {
        next({ name: 'auth-login' })
      }
    })
  }
});


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
