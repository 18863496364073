import jsonpack from 'jsonpack'
import store from '@/store/index';


export const storageCache = {
  namespaced: true,
  state: {
    id: null,
    createdAt: null,
    expired: false,
    items: [],
  },
  actions: {
/*    setItems({ commit, rootState }, payload) {
      commit('setItems', { rootState: rootState, key: payload.key, items: payload.items });
    },*/
    forceExpiration({ commit }) {
      commit('forceExpiration');
    },
    clear({ commit }) {
      commit('clear');
    },
    setItems({ commit, rootState }, payload) {
      const routes = store?.getters['storageLocal/getRoutes']
      if(routes) {
        const route = routes.find(r => r.name === payload.key)
        if (route?.enabled && route?.isCacheEnabled) {
          if(window.Worker) {
            const worker = new Worker('/workers/store.js');
            worker.postMessage({ type: 'pack', items: payload.items });
            worker.onerror = (e) => { console.error('pack', e) };
            worker.onmessage = (event) => {
              commit('setItems', { rootState: rootState, key: payload.key, items: event.data.items, isPacked: event.data.isPacked });
            };
          }
          else {
            commit('setItems', { rootState: rootState, key: payload.key, items: payload.items, isPacked: false });
          }
        }
      }
    },
  },
  mutations: {
    setItems(state, payload) {
      state.id = payload.rootState.account.id
      state.createdAt = new Date()
      state.expired = false

      if(payload.items.length) {
        if(payload?.isPacked) {
          //Set Items Pre-Packed
          state.items = payload.items
        }
        else {
          //Set Items Unpacked
          state.items = jsonpack.pack(payload.items)
        }
      }
      else { state.items = [] }
    },
    forceExpiration(state) {
      state.expired = true
    },
    clear(state) {
      state.id = null
      state.createdAt = null
      state.expired = true
      state.items = []
    }
  },
  getters: {
    //the getters module takes 3 arguments (local state, getters, root state).
    get: state => ({
        createdAt: state.createdAt,
        expired: state.expired,
        items: (typeof state.items === 'string' || state.items instanceof String) ? jsonpack.unpack(state.items) : [],
      }),
    getItems: state => {
      try {
        if(state.items.length) {
          return jsonpack.unpack(state.items)
        }
        return []
      }
      catch(e) {
        return []
      }
    },
    isExpired: (state, _, rootState) => {
      const isExpired = new Date() - new Date(state.createdAt) >= 300000 || state.expired || state.items.length === 0 || state.id !== rootState.account.id
      state.expired = isExpired
      return isExpired
    }
  }
};



export const storageDashboardCache = {
  namespaced: true,
  state: {
    id: null,
    festivals: {
      items: [],
      createdAt: null,
    },
    instruments: {
      items: [],
      createdAt: null,
    },
    forms: {
      items: [],
      createdAt: null,
    },
    zones: {
      items: [],
      createdAt: null,
    },
    ensembles: {
      items: [],
      createdAt: null,
    },
    students: {
      items: [],
      createdAt: null,
    },
  },
  actions: {
    setFestivals({ commit, rootState }, payload) {
      commit('setFestivals', { rootState: rootState, key: payload.key, items: payload.items });
    },
    setInstruments({ commit, rootState }, payload) {
      commit('setInstruments', { rootState: rootState, key: payload.key, items: payload.items });
    },
    setForms({ commit, rootState }, payload) {
      commit('setForms', { rootState: rootState, key: payload.key, items: payload.items });
    },
    setZones({ commit, rootState }, payload) {
      commit('setZones', { rootState: rootState, key: payload.key, items: payload.items });
    },
    setEnsembles({ commit, rootState }, payload) {
      commit('setEnsembles', { rootState: rootState, key: payload.key, items: payload.items });
    },
    setStudents({ commit, rootState }, payload) {
      commit('setStudents', { rootState: rootState, key: payload.key, items: payload.items });
    },

    forceExpiration({ commit }) {
      commit('forceExpiration');
    },
    clear({ commit }) {
      commit('clear');
    },
  },
  mutations: {
    setFestivals(state, payload = { items: []}) {
      const routes = store?.getters['storageLocal/getRoutes']
      if(routes) {
        const route = routes.find(r => r.name === payload.key)
        if (route?.enabled && route?.isCacheEnabled) {
          state.id = payload.rootState.account.id
          state.festivals = {
            items: jsonpack.pack(payload.items),
            createdAt: new Date()
          }
        }
      }
    },
    setInstruments(state, payload = { items: []}) {
      const routes = store?.getters['storageLocal/getRoutes']
      if(routes) {
        const route = routes.find(r => r.name === payload.key)
        if (route?.enabled && route?.isCacheEnabled) {
          state.id = payload.rootState.account.id
          state.instruments = {
            items: jsonpack.pack(payload.items),
            createdAt: new Date()
          }
        }
      }
    },
    setForms(state, payload = { items: []}) {
      const routes = store?.getters['storageLocal/getRoutes']
      if(routes) {
        const route = routes.find(r => r.name === payload.key)
        if (route?.enabled && route?.isCacheEnabled) {
          state.id = payload.rootState.account.id
          state.forms = {
            items: jsonpack.pack(payload.items),
            createdAt: new Date()
          }
        }
      }
    },
    setZones(state, payload = { items: []}) {
      const routes = store?.getters['storageLocal/getRoutes']
      if(routes) {
        const route = routes.find(r => r.name === payload.key)
        if (route?.enabled && route?.isCacheEnabled) {
          state.id = payload.rootState.account.id
          state.zones = {
            items: jsonpack.pack(payload.items),
            createdAt: new Date()
          }
        }
      }
    },
    setEnsembles(state, payload = { items: []}) {
      const routes = store?.getters['storageLocal/getRoutes']
      if(routes) {
        const route = routes.find(r => r.name === payload.key)
        if (route?.enabled && route?.isCacheEnabled) {
          state.id = payload.rootState.account.id
          state.ensembles = {
            items: jsonpack.pack(payload.items),
            createdAt: new Date()
          }
        }
      }
    },
    setStudents(state, payload = { items: []}) {
      const routes = store?.getters['storageLocal/getRoutes']
      if(routes) {
        const route = routes.find(r => r.name === payload.key)
        if (route?.enabled && route?.isCacheEnabled) {
          state.id = payload.rootState.account.id
          state.students = {
            items: jsonpack.pack(payload.items),
            createdAt: new Date()
          }
        }
      }
    },
    clear(state) {
      state.id = null
      state.festivals = {
        items: [],
        createdAt: null
      }
      state.instruments = {
        items: [],
        createdAt: null
      }
      state.forms = {
        items: [],
        createdAt: null
      }
      state.zones = {
        items: [],
        createdAt: null
      }
      state.ensembles = {
        items: [],
        createdAt: null
      }
      state.students = {
        items: [],
        createdAt: null
      }
    }
  },
  getters: { //the getters module takes 3 arguments (local state, getters, root state).
    getFestivals: state => (state.festivals.items.length
          ? { items: jsonpack.unpack(state.festivals.items), createdAt: state.festivals.createdAt }
          : { items: [], createdAt: null }),
    getInstruments: state => (state.instruments.items.length
          ? { items: jsonpack.unpack(state.instruments.items), createdAt: state.instruments.createdAt }
          : { items: [], createdAt: null }),
    getForms: state => (state.forms.items.length
          ? { items: jsonpack.unpack(state.forms.items), createdAt: state.forms.createdAt }
          : { items: [], createdAt: null }),
    getZones: state => (state.zones.items.length
        ? { items: jsonpack.unpack(state.zones.items), createdAt: state.zones.createdAt }
        : { items: [], createdAt: null }),
    getEnsembles: state => (state.ensembles.items.length
          ? { items: jsonpack.unpack(state.ensembles.items), createdAt: state.ensembles.createdAt }
          : { items: [], createdAt: null }),
    getStudents: state => (state.students.items.length
          ? { items: jsonpack.unpack(state.students.items), createdAt: state.students.createdAt }
          : { items: [], createdAt: null }),
    // eslint-disable-next-line no-shadow
    isExpired: (state, _, rootState) => (stateProp) => {
      const isExpired = state[stateProp].createdAt === null || (new Date() - new Date(state[stateProp].createdAt) >= 300000) || state.id !== rootState.account.id || state[stateProp].items.length === 0
      if(isExpired) {
        state[stateProp] = {
          items: [],
          createdAt: null
        }
      }
      return isExpired
    },
  }
};


