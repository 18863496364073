<template>
  <b-card-code v-if="$can('manage', 'debug')" :title="`Debug${title ? ':\t' + title : '' }`" title-text-tag="h5" title-text-variant="white" bg-variant="dark" text-variant="white" :code-language="codeLanguage" :visible="!collapsed">
    <template #code>
      <slot></slot>
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

export default {
  name: 'Debug',
  components: {
    BCardCode
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    codeLanguage: {
      type: String,
      default: 'javascript'
    }
  }
}
</script>
