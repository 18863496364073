export default [
    /** Event **/
    {
        path: '/events/students',
        name: 'events-students',
        component: () => import('@/views/events/students/Students.vue'),
        meta: {
            pageTitle: 'Students',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Students',
                    active: true,
                },
            ],
            subject: 'events-students',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/students/:id',
        name: 'events-student',
        props: true,
        component: () => import('@/views/events/students/Students.vue'),
        meta: {
            pageTitle: 'Students',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Students',
                    active: true,
                },
            ],
            subject: 'events-students',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/buses',
        name: 'events-buses',
        component: () => import('@/views/events/buses/Buses.vue'),
        meta: {
            pageTitle: 'Buses',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Buses',
                    active: true,
                },
            ],
            subject: 'events-buses',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/buses/:id',
        name: 'events-bus',
        props: true,
        component: () => import('@/views/events/buses/Buses.vue'),
        meta: {
            pageTitle: 'Buses',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Buses',
                },
                {
                    text: 'Bus',
                    active: true,
                },
            ],
            subject: 'events-buses',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/hotels',
        name: 'events-hotels',
        component: () => import('@/views/events/hotels/Hotels.vue'),
        meta: {
            pageTitle: 'Hotels',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Hotels',
                    active: true,
                },
            ],
            subject: 'events-hotels',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/hotels/:id',
        name: 'events-hotel',
        props: true,
        component: () => import('@/views/events/hotels/Hotels.vue'),
        meta: {
            pageTitle: 'Hotels',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Hotels',
                    active: true,
                },
            ],
            subject: 'events-hotels',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/chaperones',
        name: 'events-chaperones',
        component: () => import('@/views/events/chaperones/Chaperones.vue'),
        meta: {
            pageTitle: 'Chaperones',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Chaperones',
                    active: true,
                },
            ],
            subject: 'events-chaperones',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/chaperones/:id',
        name: 'events-chaperone',
        props: true,
        component: () => import('@/views/events/chaperones/Chaperones.vue'),
        meta: {
            pageTitle: 'Chaperones',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Chaperones',
                    active: true,
                },
            ],
            subject: 'events-chaperones',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/chaperones/print/checkins',
        name: 'events-chaperone-print-checkins',
        props: true,
        component: () => import('@/views/events/chaperones/CheckinPrint.vue'),
        meta: {
            pageTitle: 'Chaperones',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Chaperones',
                    active: true,
                },
            ],
            subject: 'events-chaperones',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/room-assignment',
        name: 'events-room-assignment',
        component: () => import('@/views/events/room-assignment/RoomAssignment.vue'),
        meta: {
            pageTitle: 'Room Assignment',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'Room Assignment',
                    active: true,
                },
            ],
            subject: 'events-room-assignment',
            action: 'read',
            hasSettings: false
        },
    },


    {
        path: '/events/badges',
        name: 'events-badges',
        component: () => import('@/views/events/badges/Badges.vue'),
        meta: {
            pageTitle: 'Badges',
            breadcrumb: [
                {
                    text: 'Events',
                },
                {
                    text: 'Badges',
                    active: true,
                },
            ],
            subject: 'events-badges',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/badges/:id',
        name: 'events-badge',
        props: true,
        component: () => import('@/views/events/badges/Badges.vue'),
        meta: {
            pageTitle: 'Badge',
            breadcrumb: [
                {
                    text: 'Events',
                },
                {
                    text: 'Badges',
                    active: true,
                },
                {
                    text: 'Badge',
                    active: true,
                },
            ],
            subject: 'events-badges',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/events/test',
        name: 'events-test',
        component: () => import('@/views/events/test/Test.vue'),
        meta: {
            pageTitle: 'test',
            breadcrumb: [
                {
                    text: 'Event',
                },
                {
                    text: 'test',
                    active: true,
                },
            ],
            subject: 'events-test',
            action: 'read',
            hasSettings: false
        },
    },


]
