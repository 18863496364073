import {Auth} from 'aws-amplify';

export default [
    /** Auth **/
    {
        name: 'auth-login',
        path: '/login',
        component: () => import('@/views/auth/Login.vue'),
        meta: {
            layout: 'full',
            subject: 'auth-login',
            redirectIfLoggedIn: true,
            hasSettings: false
        },
        async beforeEnter(to, _, next) {
            //if the user is logged in, send them to the dashboard, otherwise, this route is fine.
            await Auth.currentAuthenticatedUser().then(() => { next({ name: 'dashboard' }) }).catch(() => next())
        },
    },
    {
        name: 'auth-register',
        path: '/register',
        component: () => import('@/views/auth/Register.vue'),
        meta: {
            layout: 'full',
            subject: 'auth-register',
            redirectIfLoggedIn: true,
            hasSettings: false
        },
    },
    {
        name: 'auth-forgot-password',
        path: '/forgot-password',
        component: () => import('@/views/auth/ForgotPassword.vue'),
        meta: {
            layout: 'full',
            subject: 'auth-forgot-password',
            redirectIfLoggedIn: true,
            hasSettings: false
        },
    },
    {
        path: '/account/settings',
        name: 'account-settings',
        component: () => import('@/views/account/AccountSetting.vue'),
        meta: {
            subject: 'account-settings',
            action: 'read',
            hasSettings: false
        },
    },
]
