import {API, graphqlOperation} from 'aws-amplify';
import {getSetting} from '@/graphql/queries';
import {canManage} from '@/libs/acl/routeProtection';

/** NOTE:
 * All beforeEnter(to, _, next) methods will attempt to pull the support setting.
 * If the sub-system is enabled or the user has 'manage' role permissions the router will move them along.
 * If neither of those conditions are met, the user will be redirected to the support landing page and presented an error.
 * **/

export default [

    {
        path: '/support',
        name: 'support',
        props: true,
        component: () => import('@/views/support/Landing.vue'),
        meta: {
            subject: 'support',
            action: 'read',
        },
    },

    /** Getting Started **/
    {
        path: '/support/getting-started',
        name: 'support-getting-started',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}))
                const setting = JSON.parse(response?.data?.getSetting?.value) || null
                if(setting?.tours?.enabled || canManage(to)) {
                    to.params.enabled = setting?.tours?.enabled || false
                    next()
                }
                else {
                    next({name: 'support', params: { error: 'The Getting Started Sub-System is currently disabled.'} })
                }
            }
            catch(error) {
                console.error(error)
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/tour/Tour.vue'),
        meta: {
            subject: 'support',
            action: 'read',
            pageTitle: 'Tours',
            breadcrumb: [
                {
                    text: 'Getting Started',
                },
                {
                    text: 'Tours',
                    active: true,
                },
            ],
        },
    },

    /** Docs **/
    {
        path: '/support/docs',
        name: 'support-docs',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}));
                const setting = JSON.parse(response.data?.getSetting?.value) || null
                if(setting?.documentation?.enabled || canManage(to)) {
                    to.params.enabled = setting?.documentation?.enabled || false
                    next()
                }
                else {
                    next({name: 'support', params: { error: 'The Documentation Sub-System is currently disabled.'} })
                }
            }
            catch(e) {
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/docs/Documents.vue'),
        meta: {
            subject: 'support-docs',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Support',
                    to: { name: 'support' }
                },
                {
                    text: 'Documentation',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/support/docs/:slug',
        name: 'support-docs-category',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}));
                const setting = JSON.parse(response.data?.getSetting?.value) || null
                if(setting?.documentation?.enabled || canManage(to)) {
                    to.params.enabled = setting?.documentation?.enabled || false
                    next()
                }
                else {
                    next({name: 'support', params: { error: 'The Documentation Sub-System is currently disabled.'} })
                }
            }
            catch(e) {
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/docs/DocumentCategory.vue'),
        meta: {
            subject: 'support-docs',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Support',
                    to: { name: 'support' }
                },
                {
                    text: 'Documentation',
                    to: { name: 'support-docs' }
                },
                {
                    text: 'Category',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/support/docs/:category/:slug',
        name: 'support-docs-document',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}));
                const setting = JSON.parse(response.data?.getSetting?.value) || null
                if(setting?.documentation?.enabled || canManage(to)) {
                    to.params.enabled = setting?.documentation?.enabled || false
                    next()
                }
                else {
                    next({name: 'support', params: { error: 'The Documentation Sub-System is currently disabled.'} })
                }
            }
            catch(e) {
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/docs/DocumentContent.vue'),
        meta: {
            subject: 'support-docs',
            action: 'read',
            breadcrumb: [
                {
                    text: 'Support',
                    to: { name: 'support' }
                },
                {
                    text: 'Documentation',
                    to: { name: 'support-docs' }
                },
                {
                    text: 'Category',
                    active: true,
                },
                {
                    text: 'Document',
                    active: true,
                },
            ],
        },
    },


    /** Ticketing **/
    {
        path: '/support/tickets',
        name: 'support-tickets',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}));
                const setting = JSON.parse(response.data?.getSetting?.value) || null
                if(setting?.tickets?.enabled || canManage(to)) {
                    to.params.status = 'all'
                    to.params.enabled = setting?.tickets?.enabled || false
                    next()
                }
                else {
                    next({name: 'support', params: { error: 'The Ticketing Sub-System is currently disabled.'} })
                }
            }
            catch(e) {
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/tickets/Support.vue'),
        meta: {
            subject: 'support-tickets',
            action: 'read',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
        },
    },
    {
        path: '/support/tickets/:id',
        name: 'support-ticket',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}));
                const setting = JSON.parse(response.data?.getSetting?.value) || null
                if(setting?.tickets?.enabled || canManage(to)) {
                    to.params.enabled = setting?.tickets?.enabled || false
                    next()
                }
                else {
                    next({name: 'support', params: { error: 'The Ticketing Sub-System is currently disabled.'} })
                }
            }
            catch(e) {
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/tickets/Support.vue'),
        meta: {
            subject: 'support-ticket',
            action: 'read',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'support-tickets',
        },
    },
    {
        path: '/support/tickets/status/:status',
        name: 'support-tickets-status',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}));
                const setting = JSON.parse(response.data?.getSetting?.value) || null
                if(setting?.tickets?.enabled || canManage(to)) {
                    to.params.enabled = setting?.tickets?.enabled || false

                    if (['all', 'open', 'in-progress', 'closed'].includes(to.params.status)) next()
                    else next({ name: 'error-404' })
                }
                else {
                    next({name: 'support', params: { error: 'The Ticketing Sub-System is currently disabled.'} })
                }
            }
            catch(e) {
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/tickets/Support.vue'),
        meta: {
            subject: 'support-tickets-status',
            action: 'read',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'support-tickets',
        },
    },
    {
        path: '/support/tickets/label/:label',
        name: 'support-tickets-label',
        props: true,
        async beforeEnter(to, _, next) {
            try {
                const response = await API.graphql(graphqlOperation(getSetting, {key: 'support'}));
                const setting = JSON.parse(response.data?.getSetting?.value) || null
                if(setting?.tickets?.enabled || canManage(to)) {
                    to.params.enabled = setting?.tickets?.enabled || false

                    const labels = ['account', 'application', 'student', 'scores', 'selection', 'billing', 'bug']
                    if (labels.includes(to.params.label)) next()
                    else next({ name: 'error-404' })
                }
                else {
                    next({name: 'support', params: { error: 'The Ticketing Sub-System is currently disabled.'} })
                }
            }
            catch(e) {
                next({name: 'support', params: { error: 'Failed to load Support Settings.' } })
            }
        },
        component: () => import('@/views/support/tickets/Support.vue'),
        meta: {
            subject: 'support-ticket-label',
            action: 'read',
            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
            navActiveLink: 'support-tickets',
        },
    },
]
