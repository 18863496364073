import {API, graphqlOperation} from 'aws-amplify';
import {getSetting} from '@/graphql/queries';
import {uuid} from 'vue-uuid';
import {onUpdateSetting} from '@/graphql/subscriptions';

// eslint-disable-next-line import/prefer-default-export
export const settings = {
  namespaced: true,
  state: {
    app: null,
    subscription: null,
  },
  actions: {
    async loadSettingsApp({ commit }) {
      const response = await API.graphql(graphqlOperation(getSetting, { key: 'app' }));
      const setting = response.data.getSetting
      if(setting) {
        try {
          const parsedValue = JSON.parse(setting.value)
          const parsedValueEnabled = {
              initialId: parsedValue.current.id,
              current: parsedValue.current,
              options: parsedValue.options.filter(option => option.enabled)
          }
          commit('setSettingsApp', parsedValueEnabled);
        }
        catch(e) {
          console.error('Error while loading getSetting:app, using defaults.', e)
          const currentYear = new Date().getFullYear()
          const currentId = uuid.v4()
          const defaultAppSetting = {
            initialId: currentId,
            current: {
              id: currentId,
              title: currentYear.toString(),
              enabled: true,
              year: {
                start: `${currentYear}-01-01T00:00:00.000Z`,
                end: `${currentYear}-12-31T23:59:59.999Z`
              },
            },
            options: [
              {
                id: currentId,
                title: currentYear.toString(),
                enabled: true,
                year: {
                  start: `${currentYear}-01-01`,
                  end: `${currentYear}-12-31`
                },
              },
              {
                id: uuid.v4(),
                title: (currentYear - 1).toString(),
                enabled: true,
                year: {
                  start: `${(currentYear - 1)}-01-01T00:00:00.000Z`,
                  end: `${(currentYear - 1)}-12-31T23:59:59.999Z`
                },
              }
            ]
          }
          commit('setSettingsApp', defaultAppSetting);
        }
      }
    },
    async clear({ commit }) {
      commit('setSettingsApp', null);
    },
    /*async subscribeToSettings({ state, commit }) {
      if(state.subscription === null) {
        const subscription = API.graphql(graphqlOperation(onUpdateSetting)).subscribe(async (sourceData) => {
          const updateSetting = sourceData.value.data.onUpdateSetting;
          try {
            const parsedValue = JSON.parse(updateSetting.value)
            commit('setSettingsApp', parsedValue);
          }
          catch(e) {
            console.error(e)
          }
        });

        commit('SET_SUBSCRIPTION', subscription);
      }
    },*/
    async subscribeToSettings({ state, commit }) {
      if(state.subscription === null) {
        const subscription = API.graphql(graphqlOperation(onUpdateSetting)).subscribe({
          next: async (sourceData) => {
            const updateSetting = sourceData.value.data.onUpdateSetting;
            if(updateSetting.key === 'app') {
              try {
                const parsedValue = JSON.parse(updateSetting.value)
                commit('setSettingsApp', parsedValue);
              }
              catch (e) {
                console.error(e)
              }
            }
          },
          error: (error) => {
            console.error('Subscription error:', error);
            setTimeout(() => {
              state.subscription.unsubscribe();
              state.subscription = null;
            }, 3000); // Retry after 3 seconds
          }
        });

        commit('SET_SUBSCRIPTION', subscription);
      }
    },
    unsubscribeFromSettings({ state }) {
      if (state.subscription) {
        state.subscription.unsubscribe();
      }
    },
  },
  mutations: {
    setSettingsApp(state, payload) {
        state.app = payload
    },
    setSettingsAppCurrent(state, payload) {
      state.app.current = payload
    },
    setSettingsAppOptions(state, payload) {
      state.app.options = payload
    },
    SET_SUBSCRIPTION(state, subscription) {
      state.subscription = subscription;
    },
  },
  getters: {
    getCurrent(state) {
      return state.app?.current ?? { }
    },
    getCurrentYear(state) {
      return state.app?.current?.title ?? ''
    },
    hasSettingsApp: (state) => state.app !== null,
    getEnabledOptions: (state) => state.app?.options?.filter(option => option.enabled) || [],
    getSettingApp: (state) => state.app
  },
};

