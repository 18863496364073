import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import router from '@/router'
import store from '@/store'
import App from '@/App.vue'
import * as AmplifyModules from 'aws-amplify'
import { Amplify } from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsConfig from '@/aws-exports'
import './global-components'

import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/html-to-paper'

Amplify.configure({...awsConfig})
Vue.use(AmplifyPlugin, AmplifyModules)

// BSV Registration
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  /*created() {
    this.$store.dispatch('bindNamespaces', this.$store);
  },*/
}).$mount('#app')

