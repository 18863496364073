export default [
    /** Reports **/
    {
        path: '/reports/application-recommendation',
        name: 'reports-application-recommendation',
        component: () => import('@/views/reports/ApplicationsRecommendation.vue'),
        meta: {
            pageTitle: 'Application Recommendation',
            breadcrumb: [
                {
                    text: 'Reports',
                },
                {
                    text: 'Application Recommendation',
                    active: true,
                },
            ],
            subject: 'reports-application-recommendation',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/reports/state-ranking',
        name: 'reports-state-ranking',
        component: () => import('@/views/reports/StateRanking.vue'),
        meta: {
            pageTitle: 'State Ranking',
            breadcrumb: [
                {
                    text: 'Reports',
                },
                {
                    text: 'State Ranking',
                    active: true,
                },
            ],
            subject: 'reports-state-ranking',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/reports/part-assignment',
        name: 'reports-part-assignment',
        component: () => import('@/views/reports/PartAssignment.vue'),
        meta: {
            pageTitle: 'Part Assignment',
            breadcrumb: [
                {
                    text: 'Reports',
                },
                {
                    text: 'Part Assignment',
                    active: true,
                },
            ],
            subject: 'reports-part-assignment',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/reports/ensemble-roster',
        name: 'reports-ensemble-roster',
        component: () => import('@/views/reports/EnsembleRoster.vue'),
        meta: {
            pageTitle: 'Ensemble Roster',
            breadcrumb: [
                {
                    text: 'Reports',
                    active: true,
                },
                {
                    text: 'Ensemble Roster',
                    active: true,
                },
            ],
            subject: 'reports-ensemble-roster',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/reports/events-roster',
        name: 'reports-events-roster',
        component: () => import('@/views/reports/ChaperoneRoster.vue'),
        meta: {
            pageTitle: 'Events Roster',
            breadcrumb: [
                {
                    text: 'Reports',
                    active: true,
                },
                {
                    text: 'Events Roster',
                    active: true,
                },
            ],
            subject: 'reports-events-roster',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/reports/bus-roster',
        name: 'reports-bus-roster',
        component: () => import('@/views/reports/BusRoster.vue'),
        meta: {
            pageTitle: 'Bus Roster',
            breadcrumb: [
                {
                    text: 'Reports',
                    active: true,
                },
                {
                    text: 'Bus Roster',
                    active: true,
                },
            ],
            subject: 'reports-bus-roster',
            action: 'read',
            hasSettings: true
        },
    },

    {
        path: '/reports/selection-order',
        name: 'reports-selection-order',
        component: () => import('@/views/reports/EnsembleRoster.vue'),
        meta: {
            pageTitle: 'Selection Order',
            breadcrumb: [
                {
                    text: 'Reports',
                    active: true,
                },
                {
                    text: 'Selection Order',
                    active: true,
                },
            ],
            subject: 'reports-selection-order',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/reports/festival-applications',
        name: 'reports-festival-applications',
        component: () => import('@/views/reports/FestivalApplications.vue'),
        meta: {
            pageTitle: 'Festival Applications',
            breadcrumb: [
                {
                    text: 'Reports',
                    active: true,
                },
                {
                    text: 'Festival Applications',
                    active: true,
                },
            ],
            subject: 'reports-festival-applications',
            action: 'read',
            hasSettings: true
        },
    },
    {
        path: '/reports/multiple-selection-acceptance',
        name: 'reports-multiple-selection-acceptance',
        component: () => import('@/views/reports/MultipleSelectionAcceptance.vue'),
        meta: {
            pageTitle: 'Multiple Selection Acceptance',
            breadcrumb: [
                {
                    text: 'Reports',
                    active: true,
                },
                {
                    text: 'Multiple Selection Acceptance',
                    active: true,
                },
            ],
            subject: 'multiple-selection-acceptance',
            action: 'read',
            hasSettings: false
        },
    },
    {
        path: '/reports/multiple-selections',
        name: 'reports-multiple-selections',
        component: () => import('@/views/reports/MultipleSelections.vue'),
        meta: {
            pageTitle: 'Multiple Selections',
            breadcrumb: [
                {
                    text: 'Reports',
                    active: true,
                },
                {
                    text: 'Multiple Selections',
                    active: true,
                },
            ],
            subject: 'multiple-selection-acceptance',
            action: 'read',
            hasSettings: false
        },
    },

]
