import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import { account } from '@/store/user.module'
import storageLocal from '@/store/storage.local.module'
import { storageCache, storageDashboardCache } from '@/store/storage.cache.module'
import {settings} from '@/store/settings.module'

import app from './app'
import appConfig from './app-config'
import breadcrumb from './breadcrumb'
import verticalMenu from './vertical-menu'


Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.DEV,
  modules: {
    account,
    app,
    appConfig,
    breadcrumb,
    verticalMenu,
    storageLocal,
    dashboard: storageDashboardCache,
    'all-state-scores': storageCache,
    'all-state-applications': storageCache,
    settings,
  },
  plugins: [
    createPersistedState({ key: 'nyssma.cache.all-state-scores', storage: window.sessionStorage, paths: ['all-state-scores'] }),
    createPersistedState({ key: 'nyssma.cache.all-state-applications', storage: window.sessionStorage, paths: ['all-state-applications'] }),
    createPersistedState({ key: 'nyssma.cache.dashboard', storage: window.sessionStorage, paths: ['dashboard'] })
  ],
})

